export default {
  SET_ME(state, me) {
    state.user = me
  },

  CLEAR_ME(state) {
    state.user.id = null
    state.user.status = null
    state.user.username = null
    state.user.is_analyst = false
    state.user.is_client = false
    state.user.is_student = false
    state.user.is_superuser = false
  },

  SET_USERS(state, users) {
    state.users = users
  },

  SET_PUBLIC_MODE(state, value) {
    state.is_public_mode = value
  },

  SET_NEED_LOGIN(state) {
    state.need_login = true
  },

  UNSET_NEED_LOGIN(state) {
    state.need_login = false
  },

  SET_INFO_MESSAGE(state, message) {
    state.info_message = message
  },

  CLEAR_INFO_MESSAGE(state) {
    state.info_message = ''
  },

  UPDATE_USER(state, payload) {
    state.user = payload
  }

}