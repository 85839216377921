import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,

  state: {
    security_forms_loaded: false,
    security_forms: [],
    security: {},

    certificates_fsb: [],
    certificates_fstek: [],
  },

  actions,

  getters,

  mutations,
}