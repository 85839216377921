<template>
<section class="input_section" :style="{ width: [view_modal ? '30%' : '100%'] }">
    <div class="taginput_name">
      <p class="input_name">{{ label_name ? label_name : '' }}
        <br/>
        <span class="input_name">{{ sublabel_name ? sublabel_name : '' }}</span>
      </p>
      <button class="taginput_clean" style="opacity: 0.4" v-if="clean_field" @click.prevent="$emit('update', field_name, '')">
          Очистить поле
      </button>
    </div>
    <div class="fold_text_input" v-click-outside="closeData">
      <div class="add_input elem add_input_items">
        <form @submit.prevent="addManual(new_element_name)">
          <div class="add_input_items__element" style="padding-left: 5px">
            <div style="padding-right: 10px"
                 v-for="(elem, index) in values_list"
                 :key="elem.id"
                 :id="`exists_elements_${index + 1}`">
              {{ elem }}
              <img src="@/assets/img/close-icon.svg" @click="deleteElementOrModal(index)"  />
            </div>

            <input
              style="margin: 0"
              v-model="new_element_name"
              :id="`default_elements_${unique_id}`"
              @focus="show_fold_data = true"
              :placeholder="placeholder"
              ref="input"
            />
          </div>
        </form>
      </div>

      <div class="fold" v-if="show_fold_data">

        <div class="absent_el" v-if="no_manual">
          <p>{{ not_set_values }}</p>
        </div>

        <!-- Этот блок показывается, если нет значений и нельзя добавлять руками-->
        <div class="absent_el" v-if="!filtered_available_elements.length && no_manual">
          <img src="@/assets/img/fire-icon.svg" alt="fite" />
          <p>{{ $t('forms.process.5.per.not_values_def') }}</p>
        </div>

        <div class="new_field_wrapper" v-if="!no_manual">
          <div class="data_wrapper">
            <span style="margin-right: 15px">
              {{  new_element_name.length ? $t('general.create') : $t('general.typing') }}
            </span>

            <div
              class="data_el binded yellow"
              v-if="new_element_name.length"
            >{{ new_element_name }}</div>

          </div>
          <div
            class="press_btn"
            @click="addManual(new_element_name)"
            id="add_elem_btn"
          >
            <p>{{ $t('general.press') }}</p>
            <img src="@/assets/img/icon-keyboard-enter.svg"  />
          </div>
        </div>
      </div>
    </div>

  <delete-modal
    :show="show_delete_modal"
    :title="$t('general.modal.delete.common.title')"
    :desc="$t('general.modal.delete.common.desc')"
    @close="show_delete_modal = false"
    @approve="deleteElement(current_index)"
  ></delete-modal>

</section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'TagEmailEmit',

  props: {
    // first variant params
    label_name: String,
    sublabel_name:String,
    field_name: String,
    value: [String, Object],
    available_elements: [String, Array],
    placeholder: String,
    new_element: Object,

    // before deleting ask you in modal window
    approve: {
      type: Boolean,
      default: false
    },

    // * может понадобиться запрет на добавление собственных значений, то есть поле будет использоваться только для
    // * добавлений готовых значений, а руками вписывать ничего нельзя
    // * тогда нам нужно использовать input только для поиска и убрать блок (Начните вводить данные...)
    no_manual: {
      type: Boolean,
      default: false
    },

    // * если не заданы значения, передаем текст
    not_set_values: String,

    // * если поле текстовое - можно добавить кнопку "Отчистить поле", она просто вернет в родительский 
    // * компонент пустую строку
    clean_field: {
      type: Boolean,
      default: false
    },

    payload: {
      default: {},
    },

    tagId: {
      type: String,
      default: ''
    },
    view_modal: {
      default: false
    }
  },

  data() {
    return {
      show_fold_data: false,
      new_element_name: '',
      show_delete_modal: false,
      current_index: 0,
      unique_id: ''
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState('user', ['user']),

    values_list() {
      if (typeof this.value === 'object') {
        return this.available_elements.filter(el => this.value.includes(el.id)).map((el) => el.name)
      } else {
        return this.value?.split(/\s*;\s*/).filter((el) => el.length)
      }
    },

    filtered_available_elements() {
      if (typeof this.value === 'object') {
        return this.available_elements.filter((el) => !this.value.includes(el.id)).map(el => el.name)
      } else {
        return this.available_elements.filter((elem) => {
          return !this.values_list.includes(elem) && elem.toLowerCase().includes(this.new_element_name.toLowerCase())
        })
      }
    }
  },

  methods: {
    ...mapMutations(['DANGER_TOAST']),

    focusOnInput() {
      setTimeout(() => {
        this.$refs.input.focus()
        this.$refs.input.scrollTop = 9999
      }, 0)
    },

    cleanField() {
      this.$emit('update', '')
    },


    addManual(element) {
      if (this.no_manual) {
        return
      }
      if (element.length && !this.values_list.includes(element) && element.match(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/g)
          ) {
        const el = element.trim()
        this.values_list.push(el)
        this.$emit('update', this.field_name, this.values_list.join(';'), el)
        this.new_element_name = ''
        this.focusOnInput()
        this.closeData()
      } else {
       this.DANGER_TOAST(this.$t('general.errors.add_repeat_email'))
      }
    },

    deleteElementOrModal(index) {
      if (this.approve) {
        this.show_delete_modal = true
        this.current_index = index
      } else {
        this.deleteElement(index)
      }
    },

    deleteElement(index) {
      if (typeof this.value === 'object') {
        this.$emit(
          'update',
          this.field_name,
          this.value.filter(
            el => !this.available_elements.filter(
              el => el.name === this.values_list[index]).map(el => el.id).includes(el)
          )
        )
      } else {
        this.values_list.splice(index, 1)
        this.$emit('update', this.field_name, this.values_list.join(';'))
      }

      if (this.approve) {
        this.show_delete_modal = false
      }
    },

    closeData() {
      this.show_fold_data = false
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"

//Скрыта полосы прокрутки
::-webkit-scrollbar
  background: none
  max-height: 0
</style>