import axios from 'axios'
import UserService from '@/services/user'
import router from '@/router'


export default class Api {
  constructor(server) {
    this.server = server
    this.api_url = `${server}/api/v2`
    this.client = axios.create()
    this.userService = new UserService({ client: this.client, server: this.server })
    this.refreshRequest = null

    this.empty_response = {
      data: null,
      status: null,
      errors: [],
    }

    this.client.interceptors.request.use(config => {
      if (!localStorage.getItem('accessToken')) {

        if (this.userService.privacyHeader) {
          const newConfig = { headers: {}, ...config }
          newConfig.headers.Authorization = this.userService.privacyHeader
          return newConfig
        }

        return config
      }

      const lang = localStorage.getItem('language')
      if (lang) {
        config.headers['Accept-Language'] = lang
      }

      const newConfig = {
        headers: {},
        ...config
      }

      newConfig.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      return newConfig
    }, e => Promise.reject(e))

    this.client.interceptors.response.use(r => r, async error => {
      if (!localStorage.getItem('refreshToken') || error.response.status !== 401 || error.config.retry) {
        throw error
      }

      if (!this.refreshRequest) {
        this.refreshRequest = this.client.post(`${this.server}/api-auth/token/refresh/`, {
          refresh: localStorage.getItem('refreshToken')
        })
        try {
          const response = await this.refreshRequest
          localStorage.setItem('accessToken', response.data.access)
        }
        catch {
          await router.push({name: 'login'})
        } finally {
          this.refreshRequest = null
        }
      }

      const newRequest = {
        ...error.config,
        retry: true,
      }

      return this.client(newRequest)
    })
  }

  async query(
    type,
    url,
    context,
    payload = {},
    migration = null,
    errorMessage = '',
    successMessage= ''
  ) {
    // Универсальная функция для работы со vuex и api.
    // Работает с объектом по api, если все прошло успешно записывает изменения в store.
    switch(type) {
      case 'GET':
        try {
          const result = await this.client.get(`${this.api_url}${url}`)
          if (migration) {
            // Если передали тип миграции вызываем с полученными данными.
            // Миграция должна уметь сохранить данные в store
            context.commit(migration, result.data)
          }
          if (successMessage) {
            context.commit('SUCCESS_TOAST', successMessage, { root: true })
          }
          return result.data
        } catch (error) {
          if (errorMessage) {
            context.commit('DANGER_TOAST', errorMessage, { root: true })
          }
        }
        return false

      case 'CREATE':
        try {
          const result = await this.client.post(`${this.api_url}${url}`, payload)
          if (migration) {
            // Если передали тип миграции вызываем с полученными данными.
            // Миграция должна уметь сохранить данные в store
            context.commit(migration, result.data)
          }
          if (successMessage) {
            context.commit('SUCCESS_TOAST', successMessage, { root: true })
          }
          return result.data
        } catch (error) {
          let errors = []
          if (errorMessage) {
            errors.push(errorMessage)
          } else {
            error.response.data?.non_field_errors?.forEach(er => errors.push(er))
          }
          context.commit('DANGER_TOAST', errors.join('. '), { root: true })
        }
        return false

      case 'UPDATE':
        try {
          const result = await this.client.patch(`${this.api_url}${url}`, payload)
          if (migration) {
            // Если передали тип миграции вызываем с полученными данными.
            // Миграция должна уметь сохранить данные в store
            context.commit(migration, result.data)
          }
          if (successMessage) {
            context.commit('SUCCESS_TOAST', successMessage, { root: true })
          }
          return result
        } catch (error) {
          //Выводим сообщение об ошибки, за исключением ошибки 404 'editor_email not found'
          if (errorMessage && error.response.data.detail !== 'editor_email not found') {
            context.commit('DANGER_TOAST', errorMessage, { root: true })
          } else if (error.response?.data.detail === 'editor_email not found') {
            await context.commit('company/SET_EDITOR_EMAIL', null, {root: true})
          }
        }
        return false

      case 'LAZY_UPDATE':
        try {
          if (migration) {
            // Если передали тип миграции вызываем с полученными данными.
            // Миграция должна уметь сохранить данные в store
            context.commit(migration, payload)
          }
          const result = await this.client.patch(`${this.api_url}${url}`, payload)
          if (successMessage) {
            context.commit('SUCCESS_TOAST', successMessage, { root: true })
          }
          //Устанавливаем editor_email=true если нет 404 ошибки: 'editor_email not found'
          await context.commit('company/SET_EDITOR_EMAIL', true, {root: true})
          return result
        } catch (error) {
          //Выводим сообщение об ошибки, за исключением ошибки 404 'editor_email not found'
          if (errorMessage && error.response.data.detail !== 'editor_email not found') {
            context.commit('DANGER_TOAST', errorMessage, { root: true })
          } else if (error.response.data.detail === 'editor_email not found') {
            //Вместо ошибки 404 'editor_email not found' выводим модальное окно для ввода email
            await context.commit('company/SET_EDITOR_EMAIL', false, {root: true})
          }
        }
        return false

      case 'DELETE':
        try {
          const result = await this.client.delete(`${this.api_url}${url}`)
          if (migration) {
            // Если передали тип миграции вызываем с полученными данными.
            // Миграция должна уметь сохранить данные в store
            context.commit(migration, payload.id ? payload.id : payload.uuid)
          }
          if (successMessage) {
            context.commit('SUCCESS_TOAST', successMessage, { root: true })
          }
          return result
        } catch (error) {
          if (errorMessage) {
            context.commit('DANGER_TOAST', errorMessage, { root: true })
          }
        }
        return false
    }
  }

  // Методы для работы с Rest API
  get_url(payload) {
    // Метод собирает из объекта url. По умолчанию возвращает url к списку компаний.
    // Если передан id компании, возвращаем компанию. Если в объекте есть атрибут с именем анкеты,
    // например 'process' переходим к возвращаем ссылку на вложенный endpoint - '/api/v2/companies/1/processes/...'

    // Если в payload есть атрибут url, просто возвращаем.
    if (payload.url?.length) {
      return payload.url
    }

    let url = '/api/v2/companies/'

    if (payload.company_id) {
      url += `${payload.company_id}/`
    }

    if ('system_id' in payload) {
      url += 'systems/' + (payload.system_id ? `${payload.system_id}/` : '')
    }

    if ('process_id' in payload) {
      url += 'processes/' + (payload.process_id ? `${payload.process_id}/` : '')
    }

    if ('security_id' in payload) {
      url += 'security_forms/' + (payload.security_id ? `${payload.security_id}/` : '')
    }

    if ('system_server_id' in payload) {
      url += 'system_servers/' + (payload.system_server_id ? `${payload.system_server_id}/` : '')
    }
    if ('workstation_id' in payload) {
      url += 'workstations/' + (payload.workstation_id ? `${payload.workstation_id}/` : '')
    }
    if ('network_hardware_id' in payload) {
      url += 'network_hardware/' + (payload.network_hardware_id ? `${payload.network_hardware_id}/` : '')
    }
    // Надо добавить остальные формы
    return url
  }

  async fetch(payload) {
    try {
      const { data, status } = await this.client.get(`${this.server}${this.get_url(payload)}`)
      return {...this.empty_response, ...{ data, status }}
    } catch (error) {
      return {...this.empty_response, ...{ status: error.status, errors: [error] }}
    }
  }

  async add(payload) {
    try {
      const { data, status } = await this.client.post(
        `${this.server}${this.get_url(payload)}`,
        payload.data
        )
      return {...this.empty_response, ...{ data, status }}
    } catch (error) {
      return {...this.empty_response, ...{ status: error.status, errors: [error] }}
    }
  }

  async delete(payload) {
    try {
      const { data, status } = await this.client.delete(`${this.server}${this.get_url(payload)}`)
      return { ...this.empty_response, ...{ data, status }}
    } catch (error) {
      return { ...this.empty_response, ...{ status: error.status, errors: [error] }}
    }
  }

  async update(payload) {
    try {
      const { data, status } = await this.client.patch(
        `${this.server}${this.get_url(payload)}`,
        payload.data
      )
      return { ...this.empty_response, ...{ data, status }}
    } catch (error) {
      return { ...this.empty_response, ...{ status: error.status, errors: [error] }}
    }
  }

}