import i18n from '@/locales'
import {api} from '@/main'
import _ from "lodash"

export default {
  async fetchSystemServers({ commit }, company_id) {
    // Получаем список серверов
    const result = await api.fetch({company_id, system_server_id: null})
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('general.server.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_SYSTEM_SERVERS', result.data)
  },

  async getSystemServer({ commit }, payload) {
    const result = await api.fetch(payload)
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('company.server.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_SYSTEM_SERVER', result.data)
  },

  async addSystemServer(context, payload) {
    const result = await api.add({ company_id: payload.company, system_server_id: null, data: payload})
    if (result.status) {
      context.commit('ADD_SYSTEM_SERVER', result.data)
      await context.commit('SUCCESS_TOAST', i18n.global.t('general.success.server_create'), {root: true})
      return result.data
    }
    // Если не удается создать Сервер
    if (result.errors.length  && !result.errors[0].response.data.freemium) {
      context.commit('DANGER_TOAST', i18n.global.t('company.server.creating_error'), {root: true})
    }
    // Превышен лимит анкет для демо компании
    else context.commit('BANNER', {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail')
        }, {root: true})
  },

  async deleteSystemServer(context, payload) {
    // Удаляет сервер
    const result = await api.delete({ company_id: payload.company_id, system_server_id: payload.form_id})
    if (result.status === 204) {
      context.commit('DELETE_SYSTEM_SERVER', payload.form_id)
    }
    if (result.status) {
      await context.commit('SUCCESS_TOAST', i18n.global.t('general.success.server_delete'), {root: true})
    }
    return !result.errors.length
  },

  updateSystemServer: _.debounce((
    async ({ dispatch, commit }, payload) => {
      // Обновляем поле анкеты
      let data = {}
      data[payload.field_name] = payload.value

      const result = await api.update({
        company_id: payload.form.company,
        system_server_id: payload.form.id,
        data,
      })
      if (payload.field_name === 'name') {
        await dispatch('fetchSystemServers', payload.form.company)
      }
      if (result.errors.length) {
        commit('DANGER_TOAST', i18n.global.t('company.server.editing_error'), {root: true})
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        dispatch('getSystemServer', { company_id: payload.form.company, system_server_id: payload.form.id})
        return false
      } else {
        //Обновляем данные в анкете
        dispatch('getSystemServer', { company_id: payload.form.company, system_server_id: payload.form.id})
      }
      return true
    }
  ), 500),

}