import i18n from '@/locales'
import {api} from '@/main'
import _ from 'lodash'
import router from '@/router'
import {checkApiResponse, CompanyClient} from "@/api/clients";


export default {
  async init(context, payload= {}) {
    // Инициализация параметров и загрузка начальных данных
    if (!await context.dispatch('user/check_auth', payload, { root: true })) {
      context.commit('company/CLEAR_LOADED', null, {root: true})
      return false
    }

    // Если пользователь перешел по публичной ссылке, просто загружаем данные
    if (context.rootState.user.is_public_mode) {
      await context.dispatch('fetchAnonAllCompanyData', payload.route.params.company_id)
      return true
    }

    if (!context.state.companies_loaded) {
      await context.dispatch('fetchCompanies')
    }

    let company_id = 'id' in payload.route.params
      ? payload.route.params.id
      : context.getters['last_company_id']
    await context.dispatch('fetchAllCompanyData', company_id)

    return true
  },

  // КОМПАНИИ
  async fetchCompanies(context) {
    // Получает список всех доступных компаний.
    const companyClient = new CompanyClient()
    const result = await companyClient.fetchAll()
    context.commit('SET_COMPANIES', result.data)
  },

  async fetchAllCompanyData(context, company_id) {
    // Получаем все данные компании
    context.commit('CHANGE_COMPANY_LOADED_STATUS', false)

    const companyClient = new CompanyClient()
    const result = await companyClient.init(company_id)

    if (!await checkApiResponse(result, context, false)) {
      return false
    }
    context.commit('SET_COMPANY', result.data.company)
    context.commit('SET_SUBJECTS', result.data.subjects)
    context.commit('SET_THIRD_PARTIES', result.data.third_parties)
    context.commit('SET_COMPANY_TAGS', result.data.tags)
    context.commit(
      'process/SET_PROCESSES',
      {results: result.data.processes, count: result.data.processes.length},
      {root: true}
    )
    context.commit('system/SET_SYSTEMS', result.data.systems, {root: true})
    context.commit('SET_COMPANY_PROCESS_SYSTEMS', result.data.process_systems)
    context.commit('SET_PROCESS_SUBJECT_OTHER_ACTIONS', result.data.process_subject_other_actions)
    context.commit('SET_TECHNOLOGIES', result.data.technologies)

    //Загружаем неактивные процессы
    await context.dispatch('process/fetchInactiveProcesses', { company_id }, { root: true })

    await context.dispatch('fetchInformation', company_id)
    await context.dispatch('fetchSiteApp', company_id)
    await context.dispatch('fetchCommission', company_id)
    await context.dispatch('fetchDepartment', company_id)
    await context.dispatch('fetchOffices', company_id)
    await context.dispatch('fetchCompanyTags', company_id)
    await context.dispatch('fetchSites', company_id)
    await context.dispatch('fetchApps', company_id)
    await context.dispatch('fetchRisks', company_id)
    await context.dispatch('fetchKeys', company_id)
    // await context.dispatch('fetchComments', company_id)
    await context.dispatch('fetchSubscriptions', company_id)
    await context.dispatch('fetchMailingDate', company_id)
    await context.dispatch('fetchMacroGoals', company_id)
    await context.dispatch('fetchAddressItems', company_id)
    await context.dispatch('fetchMailing', company_id)
    await context.dispatch('fetchCompanyThirdPartiesDetails', company_id)
    await context.dispatch('system_server/fetchSystemServers', company_id, { root: true })
    await context.dispatch('workstation/fetchWorkstations', company_id, { root: true })
    await context.dispatch('network_hardware/fetchNetHardwares', company_id, { root: true })
    await context.dispatch('getDirectoryItems', company_id)
    await context.dispatch('fetchCriminals', company_id)
    await context.dispatch('fetchSecurityTechnologies', company_id)
    await context.dispatch('fetchThreat', company_id)
    await context.dispatch('fetchThreatAssesments', company_id)
    await context.dispatch('process/fetchDefaultProcesses', company_id, { root: true })
    await context.dispatch('security/getSecurityForms', company_id, { root: true })
    context.commit('CHANGE_COMPANY_LOADED_STATUS', true)
  },

  async fetchAnonAllCompanyData(context, company_id) {
    // Получаем часть данных компании для публичной ссылки
    await context.dispatch('fetchCompany', company_id)

    api.fetch({url: `/api/v2/companies/${company_id}/init_data/`, company_id})
      .then((result) => {
        // context.commit('process/SET_PROCESSES', result.data.processes, { root: true })
        context.commit('system/SET_SYSTEMS', result.data.systems, { root: true })
        context.commit('SET_SUBJECTS', result.data.subjects)
        context.commit('SET_THIRD_PARTIES', result.data.third_parties)
        context.commit('SET_COMPANY_TAGS', result.data.tags)
      })

    await context.dispatch('fetchDepartment', company_id)
    await context.dispatch('fetchSites', company_id)
    await context.dispatch('fetchApps', company_id)
    await context.dispatch('fetchRisks', company_id)
    await context.dispatch('fetchMacroGoals', company_id)
    await context.dispatch('fetchAddressItems', company_id)
    await context.dispatch('security/getSecurityForms', company_id, { root: true })
    await context.dispatch('system/fetchSystems',
        {company_id: company_id, offset:0, limit: context.state.limit_records}, { root: true })
    await context.dispatch('fetchAllSystems',{company_id: company_id})
    await context.dispatch('process/fetchProcesses',
        {company_id: company_id, offset:0, limit: context.state.limit_records}, { root: true })
    await context.dispatch('process/fetchInactiveProcesses',{company_id: company_id}, { root: true })
  },

  async fetchCompany(context, company_id) {
    const companyClient = new CompanyClient()
    const result = await companyClient.fetch(company_id)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_COMPANY', result.data)
    }
  },

  async openCompany(context, company_id) {
    localStorage.setItem('company', company_id)
    await context.dispatch('fetchAllCompanyData', company_id)
    await router.push({name: 'forms', params: {id: company_id}})
  },

  async addCompany({ commit, rootState}, company) {
    try {
      const result = await api.client.post(`${rootState.server}/api/v2/companies/`, company)
      commit('ADD_COMPANY', result.data)
    } catch (error) {
      commit('DANGER_TOAST', error.response.data.name.join(), { root: true })
    }
  },

  async deleteCompany({ commit }, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload}/`})
    commit('DELETE_COMPANY', payload)
    return !result.errors.length
  },

  updateCompany: _.debounce((
    async (context, payload) => {
      let data = {}
      data[payload.field_name] = payload.value
      await api.query(
        'UPDATE',
        `/companies/${payload.company}/`,
        context,
        data,
        'UPDATE_COMPANY',
      )
    }
  ), 500),

  async deleteCompanyDocument(context, payload) {
      let fields = {}
      fields[payload.field_name] = payload.new_uuids
      const result = await api.query(
        'UPDATE',
        `/companies/${payload.company}/`,
        context,
        fields,
        'UPDATE_COMPANY',
      )
      if (!result.error) {
        //Удаляем документ, если смогли отвязать
        await context.dispatch('deleteDocument', {uuid: payload.value})
      }
    },

  async copyCompany({ dispatch, commit, rootState }, payload) {
    try {
      await api.client.post(`${rootState.server}/api/v2/companies/${payload.company_id}/copy/`, payload)
      await dispatch('fetchCompanies')
      commit('SUCCESS_TOAST', i18n.global.t('company.copy_company'), { root: true })
    } catch (error) {
      commit('DANGER_TOAST', error.response.data, { root: true })
    }
  },

  // Действия для форм
  async fetchInformation({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/information/`, company_id})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_INFORMATION', result.data)
  },

  updateInformation: _.debounce((
    async (context, payload) => {
      let data = {}
      // Если в payload есть объект data, сохраняем его если нет обновляем поле анкеты информация
      'data' in payload ? data = {...payload.data} : data[payload.field_name] = payload.value

      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_INFORMATION', data)
      // В списке процессов проценты надо обновлять отдельно
      context.commit('UPDATE_PERCENT_INFORMATION', {
        company_id: payload.form.company,
        percent: context.getters.percentInformation
      })
      // Теперь можно актуальные проценты передать вместе с данными на сервер
      data['percent'] = context.getters.percentInformation
      const result = await api.query(
        'UPDATE',
        `/companies/${payload.form.company}/information/`,
        context,
        data,
        null,
      )

      if (!result) {
        await context.dispatch('fetchInformation', payload.form.company)
      }
    }
  ), 100),

  async loadCompanyFromApi(context, inn) {
    const result = await api.fetch({url: `/api/v2/companies/get_company_info/?inn=${inn}`})
    if (result.errors.length) {
      context.commit('DANGER_TOAST', i18n.global.t('company.loading_error'), {root: true})
      return
    }
    return result.data
  },

  async fetchSiteApp({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/site_app/`, company_id})
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('company.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_SITE_APP', result.data)
  },

  async fetchAllSiteAppsData({ dispatch }, company_id) {
    // Получаем все данные компании
    await dispatch('fetchApps', company_id)
    await dispatch('fetchSites', company_id)
    await dispatch('fetchSiteForms', company_id)
    await dispatch('fetchAppForms', company_id)
  },

  updateSiteApp: _.debounce((
    async (context, payload) => {
      // Обновляем поле анкеты сайты и приложения
      let data = {}
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_SITE_APP', data)
      // В списке процессов проценты надо обновлять отдельно
      context.commit('UPDATE_PERCENT_SITEAPP', { company_id: payload, percent: context.getters.percentSiteApp })
      // Теперь можно актуальные проценты передать вместе с данными на сервер
      data['percent'] = context.getters.percentSiteApp
      data[payload.field_name] = payload.value
      const result = await api.query(
        'UPDATE',
        `/companies/${payload.company}/site_app/`,
        context,
        data,
        null,
      )

      if (!result) {
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        await context.dispatch('fetchSiteApp', payload.company)
      }
    }
  ), 500),

  async fetchCommission({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/commission/`, company_id})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_COMMISSION', result.data)
  },

  updateCommission: _.debounce((
    async (context, payload) => {
      // Обновляем поле анкеты комиссия
      let data = {}
      data[payload.field_name] = payload.value
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_COMMISSION', data)
      // В списке процессов проценты надо обновлять отдельно
      context.commit('UPDATE_PERCENT_COMMISSION', { company_id: payload.form.company, percent: context.getters.percentCommission })
      // Теперь можно актуальные проценты передать вместе с данными на сервер
      data['percent'] = context.getters.percentCommission
      const result = await api.query(
        'UPDATE',
        `/companies/${payload.form.company}/commission/`,
        context,
        data,
        null,
      )

      if (!result) {
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        await context.dispatch('fetchCommission', payload.form.company)
      }
    }
  ), 100),

  async fetchDepartment({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/department/`, company_id})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_DEPARTMENT', result.data)
  },

  updateDepartment: _.debounce((
    async (context, payload) => {
      // Обновляем поле анкеты
      let data = {}
      data[payload.field_name] = payload.value
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_DEPARTMENT', data)
      // В списке процессов проценты надо обновлять отдельно
      context.commit('UPDATE_PERCENT_DEPARTMENT', {
        company_id: payload.form.company, percent: context.getters.percentDepartment
      })
      // Теперь можно актуальные проценты передать вместе с данными на сервер
      data['percent'] = context.getters.percentDepartment

      const result = await api.query(
        'UPDATE',
        `/companies/${payload.form.company}/department/`,
        context,
        data,
        null,
      )

      if (!result) {
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        await context.dispatch('fetchDepartment', payload.form.company)
      }
    }
  ), 100),

  async fetchSubjects({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/subjects/`})
    commit('SET_SUBJECTS', result.data)
  },

  async addSubjectGroup(context, payload) {
    await api.query(
      'CREATE',
      `/companies/${payload.company}/subjects/`,
      context,
      payload,
      'ADD_SUBJECT'
    )
    await context.dispatch('fetchSubjects', payload.company)
  },

  async updateSubject(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value

    const result = await api.update({
      url: `/api/v2/companies/${payload.company}/subjects/${payload.id}/`, data
    })
    if (!result.errors.length) {
      await context.dispatch('fetchSubjects', payload.company)
    } else {
      context.commit(
        'DANGER_TOAST',
        'Не получилось изменить группу, возможно название не уникально',
        { root: true }
      )
    }
  },

  async deleteCompanySubject({ dispatch }, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/subjects/${payload.id}/`})
    dispatch('fetchSubjects', payload.company)
    return !result.errors.length
  },

  async fetchCompanyTags({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/all_tags/`})
    commit('SET_COMPANY_TAGS', result.data)
  },

  async addTag(context, payload) {
    await api.query(
      'CREATE',
      `/companies/${payload.company}/tags/`,
      context,
      payload,
    )
    await context.dispatch('fetchCompanyTags', payload.company)
    await context.dispatch('process/fetchProcesses',
        {company_id: payload.company, offset:0, limit: context.state.limit_records}, { root: true })
    await context.dispatch('system/fetchSystems',
        {company_id: payload.company, offset:0, limit: context.state.limit_records}, { root: true })
  },

  async deleteTag(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/tags/${payload.id}/`,
      context,
      payload,
    )
    await context.dispatch('fetchCompanyTags', payload.company)
    await context.dispatch('process/fetchProcesses',
        {company_id: payload.company, offset:0, limit: context.state.limit_records}, { root: true })
    await context.dispatch('system/fetchSystems',
        {company_id: payload.company, offset:0, limit: context.state.limit_records}, { root: true })
  },

  async renameTag(context, payload) {
    try {
      await api.client.put(`${context.rootState.server}/api/v2/companies/${payload.company_id}/tags/rename/`, payload)
      await context.commit('SUCCESS_TOAST', i18n.global.t('forms.tags.success'), { root: true })
      await context.dispatch('fetchCompanyTags', payload.company_id)
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('forms.tags.error'), { root: true })
    }
  },

  async getProcessSubjects({commit, rootState}, payload) {
    try {
      const {data} = await api.client.get(
        `${rootState.server}/api/v2/companies/${payload.company}/processes/${payload.process}/subjects/`
      )
      commit('SET_PROCESS_SUBJECTS', data)
    }
    catch (error) {
      commit('DANGER_TOAST', 'Subjects loading error', { root: true })
    }
  },

  // Методы для работы со списками персональных данных
  async getDirectoryItems({ commit, rootState}, company_id) {
    try {
      const { data } = await api.client.get(`${rootState.server}/api/v2/companies/${company_id}/directory_items/`)
      commit('SET_DIRECTORY_ITEMS', data)
    } catch (error) {
      // commit('DANGER_TOAST', 'Directory loading error', { root: true })
    }
  },

  async addDirectoryItem({ rootState}, item) {
    try {
      await api.client.post(`${rootState.server}/api/v2/companies/${item.company}/directory_items/`, item)
    } catch (error) {
      // commit('DANGER_TOAST', error.response.data, { root: true })
    }
  },

  async deleteDirectoryItem({ dispatch }, item) {
    const result = await api.delete({url: `/api/v2/companies/${item.company}/directory_items/${item.id}/`})
    if (result.status) {
      dispatch('getDirectoryItems', item.company)
    } else {
      return result.errors[0].response.data
    }
  },

  async updateDirectoryItem(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value

    const result = await api.update({
      url: `/api/v2/companies/${payload.company}/directory_items/${payload.id}/`, data
    })
    if (!result.errors.length) {
      await context.dispatch('getDirectoryItems', payload.company)
    } else {
      context.commit(
        'DANGER_TOAST',
        'Не получилось изменить категорию, возможно название не уникально',
        { root: true }
      )
    }
  },

  // Методы для работы со списками макро целей
  async fetchMacroGoals({ commit, rootState}, company_id) {
    try {
      const { data } = await api.client.get(`${rootState.server}/api/v2/companies/${company_id}/macro_goals/`)
      commit('SET_MACRO_GOALS', data)
    } catch (error) {
      // commit('DANGER_TOAST', 'Macro goal loading error', { root: true })
    }
  },

  async addMacroGoal(context, item) {
    try {
      await api.client.post(`${context.rootState.server}/api/v2/companies/${item.company}/macro_goals/`, item)
      await context.dispatch('fetchMacroGoals', item.company)
    } catch (error) {
      context.commit('DANGER_TOAST', error.response.data, { root: true })
    }
  },

  async deleteMacroGoal({ dispatch }, item) {
    const result = await api.delete({url: `/api/v2/companies/${item.company}/macro_goals/${item.id}/`})
    if (result.status) {
      dispatch('fetchMacroGoals', item.company)
    } else {
      return result.errors[0].response.data
    }
  },

  // Key actions
  async fetchKeys({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/keys/`})
    if (result.errors.length) {
      return
    }
    commit('SET_KEYS', result.data)
  },

  async fetchKey(context, payload) {
    const result = await api.fetch({url: `/api/v2/companies/${payload.company}/keys/${payload.key}/`})
    if (result.errors.length) {
      return
    }
    context.commit('SET_PUBLIC_KEY', result.data)
  },

  async addKey(context, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/keys/`, data: payload})
    if (!result.errors.length) {
      context.commit('SET_KEY', result.data)
    }
  },

  async deleteKey(context, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/keys/${payload.id}/`})
    await context.dispatch('fetchKeys', payload.company)
    return !result.errors.length
  },

  async updateKey(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value

    const result = await api.update({
      url: `/api/v2/companies/${payload.company}/keys/${payload.token}/`, data
    })
    if (!result.errors.length) {
      context.commit('SET_KEY', result.data)
    }
  },

  // Offices actions
  async fetchOffices({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/offices/`})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.security.loading_error'), {root: true})
      return
    }
    commit('SET_OFFICES', result.data)
  },

  async addOffice({ dispatch }, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/offices/`, data: payload})
    dispatch('fetchOffices', payload.company)
    return !result.errors.length
  },

  async deleteOffice({ dispatch }, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/offices/${payload.id}/`})
    dispatch('fetchOffices', payload.company)
    return !result.errors.length
  },

  async updateOffice({ dispatch }, payload) {
    const result = await api.update({
      url: `/api/v2/companies/${payload.company}/offices/${payload.id}/`,
      data: payload
    })
    dispatch('fetchOffices', payload.company)
    return !result.errors.length
  },

  // Третьи лица
  async fetchThirdParties(context, company_id) {
    await api.query(
      'GET',
      `/companies/${company_id}/third_parties/`,
      context,
      {},
      'SET_THIRD_PARTIES',
    )
  },

  async fetchThirdParty(context, payload) {
    // Получаем систему
    await api.query(
      'GET',
      `/companies/${payload.company}/third_parties/${payload.system_id}/`,
      context,
      payload,
      'SET_SYSTEM',
    )
  },

  async addThirdParty(context, payload) {
    // Добавляем третье лицо
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/third_parties/`,
      context,
      payload,
      'ADD_THIRD_PARTY',
    )
  },

  async deleteThirdParty(context, payload) {
    // Удаляет процесс
    await api.query(
      'DELETE',
      `/companies/${payload.company}/third_parties/${payload.id}/`,
      context,
      payload,
      'DELETE_THIRD_PARTY',
    )
  },

  updateThirdParty: _.debounce((
    async (context, payload) => {
      // Обновляем поле третьего лица
      let data = {}
      data[payload.field_name] = payload.value
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_THIRD_PARTY', {...data, id: payload.form.id })

      const result = await api.query(
        'UPDATE',
        `/companies/${payload.company}/third_parties/${payload.form.id}/`,
        context,
        data
      )

      if (!result) {
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        await context.dispatch('fetchThirdParty', { company: payload.form.company, third_party_id: payload.form.id })
      }
    }
  ), 100),

  async addThirdPartySubject(context, payload) {
    // Добавляем третье лицо
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/third_party_subjects/`,
      context,
      payload,
      'ADD_THIRD_PARTY_SUBJECT',
    )
  },

  async deleteThirdPartySubject(context, payload) {
    // Удаляет процесс
    await api.query(
      'DELETE',
      `/companies/${payload.company}/third_party_subjects/${payload.id}/`,
      context,
      payload,
      'DELETE_THIRD_PARTY_SUBJECT',
    )
  },

  updateThirdPartySubject: _.debounce((
    async (context, payload) => {
      // Обновляем поле третьего лица
      let data = {}
      data[payload.field_name] = payload.value
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_THIRD_PARTY_SUBJECT', {...data, id: payload.form.id })

      const result = await api.query(
        'UPDATE',
        `/companies/${payload.company}/third_party_subjects/${payload.form.id}/`,
        context,
        data
      )

      if (!result) {
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        await context.dispatch(
          'fetchThirdPartySubjects',
          { company: payload.form.company, third_party_id: payload.form.id }
        )
      }
    }
  ), 100),

  // Apps actions
  async fetchApps({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/apps/`})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.security.loading_error'), {root: true})
      return
    }
    commit('SET_APPS', result.data)
  },

  async addApp({ dispatch}, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/apps/`, data: payload})
    dispatch('fetchApps', payload.company)

    return !result.errors.length
  },

  async deleteApp(context, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/apps/${payload.id}/`})
    await context.dispatch('fetchApps', payload.company)
    return !result.errors.length
  },

  updateApp: _.debounce((
    async (context, payload) => {
      // Обновляем систему
      let data = {}
      data[payload.field_name] = payload.value

      await api.query(
        'UPDATE',
        `/companies/${payload.company}/apps/${payload.id}/`,
        context,
        data,
        '',
        ''
      )
      await context.dispatch('fetchApps', payload.company)
    }
  ), 100),

  // App forms actions
  async fetchAppForms(context, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/app_forms/`})
    if (result.errors.length) {
      return
    }
    context.commit('SET_APP_FORMS', result.data)
  },

  async addAppForm(context, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/app_forms/`, data: payload})
    await context.dispatch('fetchAppForms', payload.company)
    return !result.errors.length
  },

  async deleteAppForm(context, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/app_forms/${payload.id}/`})
    await context.dispatch('fetchAppForms', payload.company)
    return !result.errors.length
  },

  updateAppForm: _.debounce((
    async (context, payload) => {
      // Обновляем систему
      // let data = {}
      // data[payload.field_name] = payload.value

      await api.query(
        'UPDATE',
        `/companies/${payload.company}/app_forms/${payload.id}/`,
        context,
        payload,
        '',
        ''
      )
      await context.dispatch('fetchAppForms', payload.company)
    }
  ), 100),

  // Sites actions
  async fetchSites(context, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/sites/`})
    if (result.errors.length) {
      context.commit('DANGER_TOAST', i18n.global.t('company.security.loading_error'), {root: true})
      return
    }
    const sites = result.data
    context.commit('SET_SITES', sites)

    // Если есть сайты, которые сейчас сканируются, через 20 секунд скачаем сайты заново
    if (sites?.filter(s => s.scan_status === 'in progress').length) {
      setTimeout(() => context.dispatch('fetchSites', company_id), 20000)
    } else {
      await context.dispatch('fetchSiteForms', company_id)
    }
  },

  async addSite({ dispatch }, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/sites/`, data: payload})
    dispatch('fetchSites', payload.company)
    return !result.errors.length
  },

  async deleteSite(context, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/sites/${payload.id}/`})
    await context.dispatch('fetchSites', payload.company)
    return !result.errors.length
  },

  updateSite: _.debounce((
    async (context, payload) => {
      // Обновляем поле сайта
      let data = {}
      data[payload.field_name] = payload.value

      await api.query(
        'UPDATE',
        `/companies/${payload.company}/sites/${payload.id}/`,
        context,
        data,
        'UPDATE_SITE',
        '',
        ''
      )
    }
  ), 300),

  async fetchSiteCookie(context, payload) {
    await api.fetch( {url: `/api/v2/companies/${payload.company_id}/sites/${payload.site}/request_cookies/`})
  },

  async getCookie(context, payload) {
    const result = await api.fetch( {url: `/api/v2/companies/${payload.company_id}/sites/${payload.site}/get_cookie_status/`})
    if (result.data.status === 'is processing') {
      // Если сканирование сайта на куки не завершено, через 10 сек проверяем статус заново
      setTimeout( () => {
        api.fetch(`/api/v2/companies/${payload.company_id}/sites/${payload.site}/get_cookie_status/`)
      }, 10000)
    }else {
      await api.fetch( {url: `/api/v2/companies/${payload.company_id}/sites/${payload.site}/get_cookies/`})
    }
  },

  // Site_forms actions
  async fetchSiteForms({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/site_forms/`})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.security.loading_error'), {root: true})
      return
    }
    commit('SET_SITE_FORMS', result.data)
  },

  async addSiteForm({ dispatch }, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/site_forms/`, data: payload})
    dispatch('fetchSiteForms', payload.company)
    return !result.errors.length
  },

  async deleteSiteForm({ dispatch }, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/site_forms/${payload.id}/`})
    dispatch('fetchSiteForms', payload.company)
    return !result.errors.length
  },

  updateSiteForm: _.debounce((
    async (context, payload) => {
      // Обновляем формы
      let data = {}
      data[payload.field_name] = payload.value

      await api.query(
        'UPDATE',
        `/companies/${payload.company}/site_forms/${payload.id}/`,
        context,
        data,
        'UPDATE_SITE_FORM',
        '',
        ''
      )
    }
  ), 300),

  // Документы
  // Получить документ
  async fetchDocument(context, payload) {
    const response = await api.fetch({url: `/api/v2/documents/${payload.uuid}/`})
    if (response.errors.length) {
      // context.commit('DANGER_TOAST', i18n.global.t('documents.loading_error'), {root: true})
      return
    }
    context.commit('ADD_DOCUMENT', response.data)
    return response.data
  },

  async addDocument(context, payload) {
    // Добавляем документ
    if (payload.link?.length) {
      const response = await api.add({url: '/api/v2/documents/', data: {link: payload.link}})
      if (response.status) {
        context.commit('ADD_DOCUMENT', response.data)
        return response.data
      } else {
        context.commit('DANGER_TOAST', response.errors[0].response.data.link[0], {root: true})
        return false
      }
    }

    // Валидация
    let filetype = payload.file.name.toLowerCase().match(/\.(\w+)$/)[1]
    let max_filesize = 30
    let allowed_types = []
    const allowedTypesMap = {
      doc: ['doc', 'docx', 'pdf', 'txt'],
      xls: ['doc', 'docx', 'pdf', 'txt', 'xls', 'xlsx'],
    }
    allowed_types = allowedTypesMap[payload.filetype] || ['jpg', 'jpeg', 'png', 'gif']

    if (filetype && !allowed_types.includes(filetype)) {
      context.commit(
        'DANGER_TOAST',
        `Добавлен файл неверного формата. Добавьте файл формата: ${allowed_types.join(', ')}`,
        { root: true }
      )
      return
    }

    // check size
    if (Number(payload.file.size) > max_filesize * 1048576) {
      context.commit(
        'DANGER_TOAST',
        `Превышен допустимый размер загружаемого файла. Максимальный размер: ${max_filesize} мб`,
        { root: true }
      )
      return
    }

    // Загружаем файл
    const fd = new FormData()
    fd.set('filename', payload.file.name)
    fd.append('file', payload.file, payload.file.name)

    const response = await api.add({url: '/api/v2/documents/', data: fd})
    if (response.errors.length) {
      context.commit('DANGER_TOAST', 'Ошибка во время добавления файла', {root: true})
      return
    }

    context.commit('ADD_DOCUMENT', response.data)
    return response.data
  },

  updateDocument: _.debounce((
    async (context, payload) => {
      // Обновляем документ
      let data = {}
      data[payload.field_name] = payload.value
      await api.query(
        'UPDATE',
        `/documents/${payload.uuid}/`,
        context,
        data,
        '',
        '',
         i18n.global.t('document.message.success'), {root: true}
      )
    }
  ), 500),

  async deleteDocument(context, payload) {
    // Удаляет документ
    return await api.query(
      'DELETE',
      `/documents/${payload.uuid}/`,
      context,
      payload,
      'DEL_DOCUMENT'
    )
  },

  // Риски
  async sendForm(context, payload) {
    try {
      await api.client(
        `${api.api_url}/companies/${payload.form.company}/risks/check_risks/`,
        {params: {form: payload.name, id: payload.form.id}}
      )
      await context.dispatch('fetchRisks', payload.form.company)
      return true
    } catch {
      return false
    }
  },

  // Чтобы после каждого изменения не скачивать список обновленных анкет можно обновить их в store
  calc_risks_in_lists(context, payload) {
    switch(payload.form_type) {
      case 'process':
        payload.context.commit(
          'process/CALC_RISKS_IN_LIST',
          {id: payload.form_id, risks: context.state.risks},
          {root: true}
        )
        break
      case 'system':
        payload.context.commit(
          'system/CALC_RISKS_IN_LIST',
          {id: payload.form_id, risks: context.state.risks},
          {root: true}
        )
        break
    }
  },

  // Отправка уведомления ответственному после того как анкета была заполнена
  async sendNotification(context, payload) {
    try {
      await api.client(
        `${api.api_url}/companies/${payload.form.company}/subscriptions/notification/?form_type=${payload.name}&form_id=${payload.form.id}`)
      return true
    } catch {
      return false
    }
  },

  async fetchRisks(context, company_id) {
    try {
      const response = await api.fetch({ url:`/api/v2/companies/${company_id}/risks/`})
      context.commit('SET_RISKS', response.data)
      return response.data
    } catch (error) {
      // context.commit('DANGER_TOAST', 'Ошибка во время загрузки рисков', {root: true})
    }
    return false
  },

  updateRisk: _.debounce((
    async (context, payload) => {
      // Обновляем поле риска

      const result = await api.query(
        'UPDATE',
        `/companies/${payload.company}/risks/${payload.risk.id}/`,
        context,
        payload.risk,
        'SET_RISK',
        '',
         i18n.global.t('risk.message.success'), {root: true}
      )

      if (!result) {
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        await context.dispatch('fetchRisks', { company_id: payload.company})
      }
      await context.dispatch(
        'calc_risks_in_lists',
        { context, form_type: result.data.form, form_id: result.data.object_id }
      )
    }
  ), 500),

  async fetchComments({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/comments/`})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.process.loading_error'), {root: true})
      return
    }
    commit('SET_COMMENTS', result.data)
  },

  async addComments({ commit }, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company_id}/comments/`, data: payload})
    commit('ADD_COMMENT', result.data)
    return !result.errors.length
  },

  async deleteComment({ dispatch }, payload) {
    const result = await api.delete({
      url: `/api/v2/companies/${payload.company_id}/comments/${payload.current_index}/`
    })
    dispatch('fetchComments', payload.company_id)
    return !result.errors.length
  },

  async fetchSubscriptions({ commit }, company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/subscriptions/`})
    if (result.errors.length) {
      // commit('DANGER_TOAST', i18n.global.t('company.loading_error'), {root: true})
      return
    }
    // Загружаем emails для рассылки в store
    commit('SET_SUBSCRIPTIONS', result.data)
  },

  async addSubscription({ commit }, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/subscriptions/`, data: payload})
    commit('ADD_SUBSCRIPTION', result.data)
    return !result.errors.length
  },

  async deleteSubscription({ commit, dispatch }, payload) {
    const result = await api.delete({
      url: `/api/v2/companies/${payload.company}/subscriptions/${payload.id}/`
    })
    commit('DEL_SUBSCRIPTION', payload.id)
    dispatch('fetchSubscriptions', payload.company)
    return !result.errors.length
  },

  async updateSubscription({ dispatch }, payload) {
    const result = await api.update({
      url: `/api/v2/companies/${payload.company}/subscriptions/${payload.id}/`,
      data: payload
    })
    dispatch('fetchSubscriptions', payload.company)
    return !result.errors.length
  },

  async fetchMailingDate({ commit }, payload) {
    const result = await api.fetch({url: `/api/v2/companies/${payload}/mail/`})
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('company.loading_error'), {root: true})
      return
    }
    // Загружаем emails для рассылки в store
    commit('SET_MAILING_DATE', result.data)
  },

  async addMailingDate({ commit }, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company}/mail/`, data: payload})
    commit('ADD_MAILING_DATE', result.data)
    return !result.errors.length
  },

  async deleteMailingDate({ commit, dispatch }, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company}/mail/${payload.id}/`})
    commit('DEL_MAILING_DATE', payload.id)
    dispatch('fetchMailingDate', payload.company)
    return !result.errors.length
  },

  async fetchDashboardData(context, payload) {
    await context.commit('dashboard/CHANGE_DASHBOARD_LOADING', true, {root: true})
    try {
      const result = await api.fetch({url: `/api/v2/companies/${payload.company}/${payload.data}/details/`})
      context.commit('SET_DETAIL', result.data)
      await context.commit('dashboard/CHANGE_DASHBOARD_LOADING', false, {root: true})
    } catch (error) {
      context.commit('DANGER_TOAST', 'Ошибка во время загрузки рисков', {root: true})
    }
  },

  async checkEditorEmail(context, payload) {
    const result = await api.fetch({url: `/api/v2/companies/${payload.company_id}/history/editor_email/`})
    if (result.data.email?.length) {
      context.commit('SET_EDITOR_EMAIL', result.data.email)
    }
    return !result.errors.length
  },

  async addEditorEmail(context, payload) {
    const result = await api.add({
      url: `/api/v2/companies/${payload.company_id}/history/editor_email/`,
      data: payload.data
    })
    if (result.data.email?.length) {
        context.commit('SET_EDITOR_EMAIL', result.data.email)
    }
    return !result.errors.length
  },

  async fetchSectionsComments(context, payload) {
    const result = await api.fetch({url: `/api/v2/companies/${payload.company_id}/comments/?content_type=${payload.form_type}&object_id=${payload.form_id}`})
    if (result.status) {
      context.commit('SET_SECTIONS_COMMENTS', result.data)
    } else {
      await context.dispatch('fetchSectionsComments', payload)
    }
  },

  async addSectionComment({ commit }, payload) {
    const result = await api.add({url: `/api/v2/companies/${payload.company_id}/comments/?content_type=${payload.field_name}&object_id=${payload.object_id}`,
      data: payload})
    commit('ADD_SECTION_COMMENT', result.data)
    return !result.errors.length
  },

  async updateSectionComment({ commit }, payload) {
    const result = await api.update({
      url: `/api/v2/companies/${payload.company_id}/comments/${payload.id}/`,
      data: payload
    })
    commit('UPDATE_SECTION_COMMENT', result.data)
    return !result.errors.length
  },

  async deleteSectionComment({ commit }, payload) {
    const result = await api.delete({url: `/api/v2/companies/${payload.company_id}/comments/${payload.id}/`})
    commit('DELETE_SECTION_COMMENT', payload.id)
    return !result.errors.length
  },

  async fetchAllSystems(context, payload) {
    const result = await api.fetch({url: `/api/v2/companies/${payload.company_id}/systems/`})
    if (result.status) {
      context.commit('system/SET_SYSTEMS', result.data, {root: true})
    }
  },

  async fetchAddressItems({commit, rootState}, company_id) {
    const result = await api.client.get(`${rootState.server}/api/v2/companies/${company_id}/address_items/`)
    if (result.status) {
      commit('SET_ADDRESS_ITEMS', result.data)
    }
  },

  async addAddressItem({ commit }, item) {
    try {
      const response = await api.add({
        url: `/api/v2/companies/${item.company}/address_items/`,
        data: item
      })
      commit('ADD_ADDRESS_ITEM', response.data)
      return response.data
    } catch (error) {
      commit('DANGER_TOAST', i18n.global.t('directory.paper_storage.add_error'), {root: true})
    }
  },

  async deleteAddressItem({ commit }, item) {
    const result = await api.delete({url: `/api/v2/companies/${item.company}/address_items/${item.id}/`})
    if (result.status) {
      commit('DELETE_ADDRESS_ITEM', item.id)
    } else {
      return result.errors[0].response.data
    }
  },

  async updateAddressItem(context, payload) {
    let data = {}
    data[payload.field_name] = payload.value

    const result = await api.update({
      url: `/api/v2/companies/${payload.company_id}/address_items/${payload.id}/`, data
    })
    if (!result.errors.length) {
      await context.dispatch('fetchAddressItems', payload.company_id)
    } else {
      context.commit(
        'DANGER_TOAST',
        'Не получилось изменить адрес, возможно название не уникально',
        { root: true }
      )
    }
  },

  async sendLinkForm(context, payload) {
    try {
      await api.client.post(`${context.rootState.server}/api/v2/companies/${payload.company}/mail/send_link/`, payload)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.modal.link.message.success')+`${payload.responsible_emails}`, {root: true})
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('general.modal.link.message.error'), { root: true })
    }
  },

  async sendLinkTags(context, payload) {
    try {
      await api.client.post(`${context.rootState.server}/api/v2/companies/${payload.company}/mail/send_link_tags/`, payload)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.modal.link.message.success')+`${payload.responsible_emails}`, {root: true})
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('general.modal.link.message.error'), { root: true })
    }
  },

  async fetchMailing({commit, rootState}, company_id) {
    const result = await api.client.get(`${rootState.server}/api/v2/companies/${company_id}/mail_context/`)
    if (result.status) {
      commit('SET_MAILING_CONTEXT', result.data)
    }
  },

  async editMailing(context, payload) {
    try {
      let result = await api.client.put(`${context.rootState.server}/api/v2/companies/${payload.company}/mail_context/`, payload)
      context.commit('SET_MAILING_CONTEXT', result.data)
      context.commit('SUCCESS_TOAST', i18n.global.t('settings.mailing.custom.success'), {root: true})
    } catch (error) {
      context.commit('DANGER_TOAST', i18n.global.t('settings.mailing.custom.warn.update'), {root: true})
    }
  },

  async fetchCompanyThirdPartiesDetails(context,  company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/third_parties/details`})
    if (result.status) {
      context.commit('SET_COMPANY_THIRD_PARTIES', result.data)
    }
  },

  //Проверяем была ли ранее создана модель угроз - негативные последствия
  async fetchThreat(context,  company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/threats/`})
    if (result.status) {
      context.commit('SET_THREAT_FORM', result.data)
    }
  },

  async fetchCriminals(context,  company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/criminals/`})
    if (result.status) {
      context.commit('SET_CRIMINALS', result.data)
    }
  },

   async fetchSecurityTechnologies(context,  company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/security_technologies/`})
    if (result.status) {
      context.commit('SET_SECURITY_TECHNOLOGIES', result.data)
    }
  },

  async fetchThreatAssesments(context,  company_id) {
    const result = await api.fetch({url: `/api/v2/companies/${company_id}/threat_assesments/`})
    if (result.status) {
      context.commit('SET_THREAT_ASSESMENTS', result.data)
    }
  },

}
