<template>
  <div class="document_comment">

    <span v-if="!document.comment.length && !show_input" 
          class="no_comment" 
          @click="focusInput">{{ $t('documents.upload.comments.add') }}</span>

    <textarea v-model="document.comment"
           class="area_section"
           v-if="show_input"
           @input="mixin_autoResize_resize"
           @blur="saveComment()"
           rows='1'
           ref="input" />

    <p v-if="!show_input" @click="focusInput" class="update_comment">{{ document.comment }}</p>
  </div>
</template>

<script>
/* 
* Получает id документа и позволяет добавлять/удалять/обновлять комментарии
* Комментарий - просто поле в модели без ограничений и его просто нужно обновлять в документе
* Возникающая проблема - документы содержатся в разных моделях и нужно передавать сокращенное название модели
* на бэкенде если словарь с данными
*/
import autoResize from '@/components/global/common/autoResize.js'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'DocumentComment',

  mixins: [autoResize],

  props: {
    'document': Object,
    'model_class': String 
  },

  data() {
    return {
      show_input: false,
    }
  },

  computed: {
    ...mapState(['server'])
  },

  methods: {
    ...mapMutations(['SUCCESS_TOAST']),

    focusInput() {
      this.show_input = true
      setTimeout(() => {
        this.$refs.input.focus()
        this.$refs.input.style.height = "auto"
        this.$refs.input.style.height = `${this.$refs.input.scrollHeight}px`
      }, 0)
    },

    saveComment() {
      let doc_object = this.document
      doc_object.model_class = this.model_class

      // проверка если хитрый пользователь оставил один пробел
      let erase = this.document.comment.replace(/\s*/g, '')
      if (erase.length === 0) {
        this.document.comment = ''
      }
        let success = this.$t('documents.upload.comments.message')
        this.$api.client.put(`${this.server}/api/v2/documents/${this.document.uuid}/`,
            doc_object)
            .then(() => {
              this.SUCCESS_TOAST(success)
              this.show_input = false
            })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/style.sass"
</style>