export default {
  dashboard: {
    systems: [],
    third_parties: [],
    subjects: [],
    sites: [],
    crossborder: []
  },

  selected_dashboard_block: 0,
  dashboard_loading: false,


  detail: []
}
