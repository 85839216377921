<template>
  <section :class="['input_section']">
    <label :for="`input_id_${unique_id}`" v-click-outside="closeComment">
      <div :ref="`board_${unique_id}`" :class="{'disabled-block': !!public_key?.comments_only }">
        <p class="input_name">{{ label_name }} <span style="color: red" v-if="required">*</span>
        <span v-if="filling_status">
          <img v-if="value" class="checkmark" src="@/assets/img/green-check.svg" alt="">
          <img v-else class="checkmark no_filled" src="@/assets/img/black-check.svg" alt="">
        </span>
        </p>
        <div :class="['text_input', validation_error ? 'input_error' : '']">

          <textarea
            :class="{'active_scroll': !!public_key?.comments_only }"
            v-model="local_value"
            :disabled="!!public_key?.comments_only"
            @input="$emit('update', field_name, $event.target.value)"
            :id="`input_id_${unique_id}`"
            :placeholder="placeholder"
        />
          <div class="icon" v-if="user.is_analyst">
            <img
                src="@/assets/img/add_comment.svg"
                alt="add_comment.svg"
                @click="show_comment = true"
                v-if="comment && user.is_analyst"
            />
            <div class="number_of_comments" v-if="commentsSorted.length">
              {{ commentsSorted ? commentsSorted.length : '' }}
            </div>
          </div>
        </div>
      </div>

      <div
          class="comment_board"
          v-if="show_comment && comment && !user.is_client"
          :style="`top: ${board_height}`">
        <form @submit.prevent="addCommentFunc">
          <div class="add_comment">
<!--            <div class="user">-->
<!--              <div class="user_avatar">{{ user.username[0].toUpperCase() }}</div>-->
<!--            </div>-->
            <input v-model="new_comment.text" required :placeholder="$t('general.add_comment')"/>

            <div class="comment_buttons">
              <div :class="['add_button', is_comment_filled ? 'success' : '']"
                   @click="addCommentHandle"
              >{{ $t('general.add') }}
              </div>
            </div>
          </div>
        </form>
        <!-- already created comments -->
        <div class="created_comments">
          <div class="detail_comment" v-for="(c, index) in commentsSorted" :key="index">
<!--              <div class="user">-->
<!--                <div-->
<!--                    class="user_avatar"-->
<!--                    :style="`background: ${c.create_by.color}`"-->
<!--                >{{ c.create_by }}-->
<!--                </div>-->
<!--              </div>-->

              <div class="text">
                <div class="comment_title">
                  <div class="info"
                    v-for="create_by in createByUser(c.create_by)"
                    :key="create_by.id">
                    <span class="name">{{ create_by.username }}</span>
                    <span class="date">{{ c.created.split('T')[0] }}</span>
                  </div>
                  <div class="action">
                    <button
                      class="delete_button"
                      @click="show_delete_comment = true;
                      current_comment_index = c.id"
                      type="button"
                    >{{ $t('general.delete') }}
                    </button>
                  </div>
                </div>

                <p class="comment_text">
                  {{ c.text }}
                </p>
              </div>
          </div>
        </div>
      </div>

      <p class="error_text" v-if="validation_error">{{ validation_error }}</p>
      <delete-modal
        @approve="handleDeleteComment"
        @close="show_delete_comment = false"
        :show="show_delete_comment"
        title="Удаление комментария"
        desc="Вы уверены, что хотите удалить данный комментарий?"
      ></delete-modal>
    </label>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: "TextareaInputEmit",

  props: {
    value: String,
    field_name: String,
    label_name: String,
    placeholder: String,
    required: Boolean,
    error: String,
    tagId: {
      type: String,
      default: ''
    },
    // comment params
    comment: String, //  example: information.ogrn. information - form name for get model in backend, ogrn - field name for creating comments
    obj: String,
    filling_status: Boolean,
  },

  emits: ['update'],

  data() {
    return {
      new_comment: {
        text: ''
      },
      show_delete_comment: false,
      current_comment_index: 0,

      show_comment: false,
      local_value: this.value,
    }
  },

  watch: {
    value(val) {
      this.local_value = val
    },
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState(['server', 'auth_headers']),
    ...mapState('company', ['company', 'comments', 'users', 'public_key']),
    ...mapState('process', ['process']),
    ...mapState('system', ['system']),
    ...mapState('user', ['user']),

    validation_error() {
      if (this.error && this.error.length) {
        return this.error
      }
      return ''
    },

    is_comment_filled() {
      return !!this.new_comment.text.length
    },

    board_height() {
      return this.$refs[`board_${this.unique_id}`].offsetHeight + 'px'
    },

    commentsSorted() {
      let comment = this.comment
      let commentsSorted = [...this.comments]
      return commentsSorted.filter(c => c.field_name === comment && c.object_id===this.getObjectId)
    },

    getObjectId() {
      return this.obj==='process' ? this.process.id : this.system.id
    }

  },

  methods: {
    ...mapActions('company', ['addComments', 'deleteComment']),

    closeComment() {
      this.show_comment = false
    },

    handleDeleteComment() {
      let cid = this.company.id
      let ci = this.current_comment_index
      this.deleteComment({current_index: ci, company_id: cid})
      this.show_delete_comment = false
      this.show_comment = true
    },

    addCommentHandle() {
      this.show_comment = true
      if(this.new_comment?.text?.length) {
        this.addComments({
          content_type: 9,
          create_by: parseInt(this.user.id),
          field_name: this.field_name,
          object_id: this.getObjectId,
          text: this.new_comment.text,
          company_id: this.company.id
        })
      }
      this.new_comment.text = ''
    },

    createByUser(id) {
      return this.users.filter(user => user.id === id)
    }
  }

}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>