<template>
<section class="input_section">

    <p class="input_name" v-if="!no_name">{{ field_name }} <span class="red" v-if="required">*</span></p>

    <div class="custom_select" v-click-outside="closeSelect">
      <div :class="['parent_block', color && modelValue === 'published' ? 'selected_parent_block' : '']" @click="show_fold_menu = true">
        <div class="text">{{ getFieldValue() }}</div>
        <div class="icon" v-if="show_fold_menu"><img src="@/assets/img/arrow-down-gray.svg" /></div>
        <div class="icon" v-if="!show_fold_menu"><img src="@/assets/img/arrow-up-gray.svg" /></div>
      </div>

      <div class="fold_block" v-if="show_fold_menu">
        <div :class="['block', isValueEqualField(option) ? 'selected' : '']" 
             v-for="(option, index) in options" 
             :key="index"
             @click="selectValue(option)">
          <div class="text">{{ typeof(option) === 'object' ? option.name : option }}</div>
          <img v-if="is_hints && option.hint" class="list_hint" src="@/assets/img/question.svg" :title="option.hint" />
          <div class="icon"><img src="@/assets/img/check-icon.svg" v-if="isValueEqualField(option)"/></div>
        </div>
      </div>
    </div>


</section>
</template>

<script>
/*
Первый вариант.
У нас есть список значений array с string и мы должны выбрать один. Отображаться будет значение
<select-input v-model="user.status"
        :options="['analyst', 'client']"
        field_name="Роль пользователя"></select-input>

Второй вариант
У нас есть Array только вместо строк дикты, в которых обязательно должны быть поля name - будет отображаться,
и value, который будет в v-model улетать
<select-input v-model="user.status"
        :options="[{name: 'Аналитик', value: 'analyst'}, {name: 'Аналитик', value: 'analyst'}]"
        field_name="Роль пользователя"></select-input>
*/
export default {
  name: 'select-input',
  
  props: {
    modelValue: [String, Number],
    field_name: String,
    options: Array,
    no_name: Boolean,
    default_element: Number,
    required: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: false
    },
    is_hints: {
      type: Boolean,
      default: false,
    }
  },
  
  data() {
    return {
      show_fold_menu: false,
      selected_field: this.modelValue,
    }
  },

  watch: {
    selected_field: {
      handler() {
        this.$emit('update:modelValue', this.selected_field)
      }
    }
  },

  mounted() {
    this.default_element ? this.$emit('update:modelValue', this.default_element) : ''
  },

  methods: {
    closeSelect() {
      this.show_fold_menu = false
    },

    getFieldValue() {
      for (let i=0; i<this.options.length; i++) {
        if (typeof(this.options[i]) === 'object') {
          if (this.options[i].value === this.modelValue) {
            return this.options[i].name
          } 
        } else {
          if (this.options[i] === this.modelValue) {
            return this.options[i]
          }
        }
      }
    },

    isValueEqualField(value) {
      /* Need to check type first, if it just string - need to check */
      if (typeof(value) === 'object') {
         if (String(value.value) === String(this.modelValue)) {
           return true
         }
      } else {
        if (value === this.modelValue) {
          return true
        }
      }
      return false
    },

    selectValue(option) {
      if (typeof(option) === 'object') {
        this.$emit('update:modelValue', option.value)
      } else {
        this.$emit('update:modelValue', option)
      }
      this.closeSelect()
    }
  },

}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>