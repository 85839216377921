export default {
  SET_SECURITY_FORMS(state, forms) {
    state.security_forms = forms
    state.security_forms_loaded = true
  },

  SET_SECURITY_FORM(state, form) {
    state.security = form
  },

  UPDATE_SECURITY_FORM(state, new_data) {
    state.security = {...state.security, ...new_data}
  },

  DELETE_SECURITY(state, id) {
    state.security_forms.splice(state.security_forms.findIndex(s => s.id === id), 1)
  },

  UPDATE_PERCENT_IN_LIST(state, payload) {
    // Чтобы можно было не скачивать список всех анкет обновляем проценты в state
    state.security_forms[state.security_forms.findIndex(form => form.id === payload.form_id)].percent = payload.percent
  },

  SET_CERTIFICATE_FSB(state, certs) {
    state.certificates_fsb = certs
  },

  SET_CERTIFICATE_FSTEK(state, certs) {
    state.certificates_fstek = certs
  },

}
