import {da} from "date-fns/locale";

export default {
  SET_PROCESSES(state, data) {
    if (data) {
      state.processes = data.results
      state.total_processes = data.count
    }
  },

  SET_INACTIVE_PROCESSES(state, processes) {
    state.inactive_processes = processes
  },

  SET_INIT_PROCESS_DATA(state, data) {
    state.process = data.process
    state.process_subjects = data.process_subjects
    state.positions = data.positions
    state.public_sources = data.public_sources
    state.other_sources = data.other_sources
    state.source_subjects = data.source_subjects
    state.paper_docs = data.paper_docs
    state.process_systems = data.process_systems
    state.process_system_subjects = data.process_system_subjects
    state.process_third_parties = data.process_third_parties
    state.process_third_party_subjects = data.process_third_party_subjects
  },

  SET_DEFAULT_PROCESSES(state, processes) {
    state.default_processes = processes
  },

  ADD_PROCESS(state, process) {
    state.processes.push(process)
  },

  SET_PROCESS(state, process) {
    state.process = process
  },

  UPDATE_PROCESS(state, {fields, getters}) {
    const idx_in_processes = state.processes.findIndex(p => p.id === state.process.id)
    Object.keys(fields).forEach(key => {
      state.process[key] = fields[key]

      if (['name', 'responsible_email', 'responsible_email_additional'].includes(key)) {
        state.processes[idx_in_processes][key] = fields[key]
      }
      try {
        state.processes[idx_in_processes].percent = getters.percent
      } catch (e) {
        // console.log(e)
      }
    })
  },

  DELETE_PROCESS(state, id) {
    state.processes.splice(state.processes.findIndex(p => p.id === id), 1)
  },

  UPDATE_PERCENT_IN_LIST(state, payload) {
    // Чтобы можно было не скачивать список всех анкет обновляем проценты в state
    if (this.state.process.processes.length && !payload.form.default_status) {
      const idx = this.state.process.processes.findIndex(p => p.id === payload.form_id)
      if (this.state.process.processes[idx]) {
        this.state.process.processes[idx].percent = payload.percent
      }
    } else if (payload.default_status && state.default_processes.length) {
      state.default_processes[
        state.default_processes.findIndex(p => p.id === payload.form_id)
      ].percent = payload.percent
    }
  },

  SET_PROCESS_SUBJECTS(state, subjects) {
    state.process_subjects = subjects
  },

  ADD_PROCESS_SUBJECT(state, subject) {
    state.process_subjects.push(subject)
    state.process.subjects.push(subject.id)
  },

  DELETE_PROCESS_SUBJECT(state, id) {
    state.process_subjects.splice(state.process_subjects.findIndex(s => s.id === id), 1)
  },

  UPDATE_PROCESS_SUBJECT(state, payload) {
    state.process_subjects[state.process_subjects.findIndex(s => s.id === payload.id)] = {
      ...state.process_subjects[state.process_subjects.findIndex(s => s.id === payload.id)], ...payload
    }
  },

  SET_POSITIONS(state, positions) {
    state.positions = positions
  },

  ADD_POSITION(state, position) {
    state.positions.push(position)
    state.process.positions.push(position.id)
  },

  DELETE_POSITION(state, id) {
    state.positions.splice(state.positions.findIndex(p => p.id === id), 1)
  },

  SET_SOURCE_SUBJECTS(state, subjects) {
    state.source_subjects = subjects
  },

  ADD_SOURCE_SUBJECT(state, subject) {
    state.source_subjects.push(subject)
  },

  UPDATE_SOURCE_SUBJECT(state, data) {
    state.source_subjects[state.source_subjects.findIndex(el => el.id === data.id)] = {...data}
  },

  DELETE_SOURCE_SUBJECT(state, id) {
    state.source_subjects.splice(state.source_subjects.findIndex(s => s.id === id), 1)
  },

  SET_PUBLIC_SOURCES(state, public_sources) {
    state.public_sources = public_sources
  },

  ADD_PUBLIC_SOURCE(state, public_source) {
    state.public_sources.push(public_source)
  },

  DELETE_PUBLIC_SOURCE(state, id) {
    state.public_sources.splice(state.public_sources.findIndex(el => el.id === id), 1)
  },

  SET_OTHER_SOURCES(state, other_sources) {
    state.other_sources = other_sources
  },

  ADD_OTHER_SOURCE(state, other_source) {
    state.other_sources.push(other_source)
  },

  DELETE_OTHER_SOURCE(state, id) {
    state.other_sources.splice(state.other_sources.findIndex(el => el.id === id), 1)
  },

  SET_PAPER_DOCS(state, paper_docs) {
    state.paper_docs = paper_docs
  },

  ADD_PAPER_DOCS(state, paper_doc) {
    state.paper_docs.push(paper_doc)
  },

  DELETE_PAPER_DOC(state, id) {
    state.paper_docs.splice(state.paper_docs.findIndex(el => el.id === id), 1)
  },

  UPDATE_PAPER_DOC(state, data) {
    state.paper_docs[state.paper_docs.findIndex(el => el.id === data.id)] = {...data}
  },

  SET_PROCESS_SYSTEMS(state, process_systems) {
    state.process_systems = process_systems
  },

  ADD_PROCESS_SYSTEM(state, system) {
    state.process_systems.push(system)
  },

  DELETE_PROCESS_SYSTEM(state, id) {
    state.process_systems.splice(state.process_systems.findIndex(el => el.id === id), 1)
  },

  UPDATE_PROCESS_SYSTEM(state, data) {
    state.process_systems[state.process_systems.findIndex(el => el.id === data.id)] = {...data}
  },

  SET_PROCESS_SYSTEM_SUBJECTS(state, process_system_subjects) {
    state.process_system_subjects = process_system_subjects
  },

  ADD_PROCESS_SYSTEM_SUBJECT(state, process_system_subject) {
    state.process_system_subjects.push(process_system_subject)
  },

  DELETE_PROCESS_SYSTEM_SUBJECT(state, id) {
    state.process_system_subjects.splice(state.process_system_subjects.findIndex(el => el.id === id), 1)
  },

  UPDATE_PROCESS_SYSTEM_SUBJECT(state, data) {
    state.process_system_subjects[state.process_system_subjects.findIndex(el => el.id === data.id)] = {...data}
  },

  CALC_RISKS_IN_LIST(state, payload) {
    const id = this.state.process.processes.findIndex(p => p.id === payload.id)
    this.state.process.processes[id].has_risks = !!payload.risks.filter(
      r => r.form === 'process' && r.object_id === payload.id
    ).length
    this.state.process.processes[id].has_active_risks = !!payload.risks.filter(
      r => r.form === 'process' && r.object_id === payload.id && !r.ignore
    ).length
  },

  SET_PROCESS_THIRD_PARTIES(state, process_third_parties) {
    state.process_third_parties = process_third_parties
  },

  ADD_PROCESS_THIRD_PARTY(state, process_third_party) {
    state.process_third_parties.push(process_third_party)
  },

  DELETE_PROCESS_THIRD_PARTY(state, id) {
    state.process_third_parties.splice(state.process_third_parties.findIndex(e => e.id === id), 1)
  },

  UPDATE_PROCESS_THIRD_PARTY(state, data) {
    const idx = state.process_third_parties.findIndex(el => el.id === data.id)
    state.process_third_parties[idx] = {...state.process_third_parties[idx], ...data}
  },

  SET_PROCESS_THIRD_PARTY_SUBJECTS(state, process_third_party_subjects) {
    state.process_third_party_subjects = process_third_party_subjects
  },

  ADD_PROCESS_THIRD_PARTY_SUBJECT(state, subject) {
    state.process_third_party_subjects.push(subject)
  },

  DELETE_PROCESS_THIRD_PARTY_SUBJECT(state, id) {
    state.process_third_party_subjects.splice(
      state.process_third_party_subjects.findIndex(e => e.id === id), 1
    )
  },

  UPDATE_PROCESS_THIRD_PARTY_SUBJECT(state, data) {
    state.process_third_party_subjects[
      state.process_third_party_subjects.findIndex(el => el.id === data.id)
    ] = {...data}
  },

  FILTER_PROCESSES(state, processes) {
    state.processes = processes
  },

  UPDATE_SERVER_RENT(state, data) {
    state.paper_docs[state.paper_docs.findIndex(el => el.id === data.id)] = {...data}
  },

  UPDATE_PROCESS_STATUS(state, process) {
    state.processes[state.processes.findIndex(el => el.id === process.id)]['form_status'] = process.status
  },
}
