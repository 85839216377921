export default {
  SET_SYSTEMS(state, systems) {
    if (systems) {
      state.systems = systems.results || systems
      state.total_systems = systems.count
    }
  },

  SET_SYSTEM(state, system) {
    state.system = system
  },

  SET_INIT_SYSTEM_DATA(state, data) {
    state.system = data.system
    state.others = data.other_sources
    state.system_subjects = data.system_subjects
    state.system_third_parties = data.system_third_parties
    state.system_third_party_subjects = data.system_third_party_subjects
  },

  ADD_SYSTEM(state, system) {
    state.systems.push(system)
  },

  UPDATE_SYSTEM(state, {id, fields, getters}) {
    const idx_in_systems = state.systems.findIndex(s => s.id === id)

    Object.keys(fields).forEach(key => {
      state.system[key] = fields[key]

      if (['name', 'responsible_email', 'responsible_email_additional', 'has_screens', 'screenshots'].includes(key)) {
        state.systems[idx_in_systems][key] = fields[key]
      }
    })
    state.systems[idx_in_systems].percent = getters.percent
  },

  UPDATE_SYSTEMS_FIELD(state, systems) {
    state.systems.map(system => {
      const new_danger_level = systems.find(s=>s.system === system.id)
      system['danger_level'] = new_danger_level['danger_level']
    })
  },

  DELETE_SYSTEM(state, id) {
    state.systems.splice(state.systems.findIndex(s => s.id === id), 1)
  },

  UPDATE_PERCENT_IN_LIST(state, payload) {
    // Чтобы можно было не скачивать список всех анкет обновляем проценты в state
    if (this.state.systems.systems) {
      if (this.state.system.systems[this.state.system.systems
          .findIndex(s => s.id === payload.form_id)]) {
        this.state.system.systems[this.state.system.systems
            .findIndex(s => s.id === payload.form_id)].percent = payload.percent
      }
    }
  },

  SET_SYSTEM_SOURCE_OTHERS(state, others) {
    state.others = others
  },

  ADD_SYSTEM_SOURCE_OTHER(state, other) {
    state.others.push(other)
    state.system.linked_others.push(other.id)
  },

  DELETE_SYSTEM_SOURCE_OTHER(state, id) {
    state.others.splice(state.others.findIndex(o => o.id === id), 1)
    state.system.linked_others.splice(state.linked_others.findIndex(o => o === id), 1)
  },

  SET_SYSTEM_SUBJECTS(state, system_subjects) {
    state.system_subjects = system_subjects
  },

  ADD_SYSTEM_SUBJECT(state, system_subject) {
    state.system_subjects.push(system_subject)
  },

  UPDATE_SYSTEM_SUBJECT(state, system_subject) {
    state.system_subjects[state.system_subjects.findIndex(s => s.id === system_subject.id)] = system_subject
  },

  DELETE_SYSTEM_SUBJECT(state, id) {
    state.system_subjects.splice(state.system_subjects.findIndex(s => s.id === id), 1)
  },

  SET_SYSTEM_THIRD_PARTIES(state, third_parties) {
    state.system_third_parties = third_parties
  },

  ADD_SYSTEM_THIRD_PARTY(state, third_party) {
    state.system_third_parties.push(third_party)
  },

  DELETE_SYSTEM_THIRD_PARTY(state, id){
    state.system_third_parties.splice(state.system_third_parties.findIndex(e => e.id === id), 1)
  },

  UPDATE_SYSTEM_THIRD_PARTY(state, data) {
    const idx = state.system_third_parties.findIndex(el => el.id === data.id)
    state.system_third_parties[idx] = {...state.system_third_parties[idx], ...data}
  },

  SET_SYSTEM_THIRD_PARTY_SUBJECTS(state, subjects) {
    state.system_third_party_subjects = subjects
  },

  ADD_SYSTEM_THIRD_PARTY_SUBJECT(state, subject) {
    state.system_third_party_subjects.push(subject)
  },

  DELETE_SYSTEM_THIRD_PARTY_SUBJECT(state, id) {
    state.system_third_party_subjects.splice(
      state.system_third_party_subjects.findIndex(e => e.id === id), 1
    )
  },

  UPDATE_SYSTEM_THIRD_PARTY_SUBJECT(state, data) {
    state.system_third_party_subjects[
      state.system_third_party_subjects.findIndex(el => el.id === data.id)
    ] = {...data}
  },

  CALC_RISKS_IN_LIST(state, payload) {
    const id = this.state.system.systems.findIndex(p => p.id === payload.id)
    state.systems[id].has_active_risks = !!payload.risks.filter(
      r => r.form === 'system' && r.object_id === payload.id && !r.ignore
    ).length
  },

  FILTER_SYSTEMS(state, systems) {
    state.systems = systems
  },

  SET_SYSTEM_INTERACTIONS(state, data) {
    state.system_interactions = data
  },

  ADD_SYSTEM_INTERACTION(state, system) {
    state.system_interactions.push(system)
  },

  UPDATE_SYSTEM_INTERACTION(state, data) {
    state.system_interactions[
      state.system_interactions.findIndex(el => el.id === data.id)
    ] = {...data}
  },

  DELETE_SYSTEM_INTERACTION(state, id) {
    state.system_interactions.splice(
      state.system_interactions.findIndex(e => e.id === id), 1
    )
  },

  UPDATE_SYSTEM_STATUS(state, system) {
   state.systems[state.systems.findIndex(el => el.id === system.id)]['form_status'] = system.status
  },

}
