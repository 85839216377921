import i18n from '@/locales'
import {api} from '@/main'
import _ from "lodash"

export default {
  async fetchNetHardwares({ commit }, company_id) {
    // Получаем список сетевых оборудований
    const result = await api.fetch({company_id, network_hardware_id: null})
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('general.network_hardware.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_NETWORK_HARDWARES', result.data)
  },

  async getNetHardware({ commit }, payload) {
    // Получаем сетевое оборудование
    const result = await api.fetch(payload)
    if (result.errors.length) {
      commit('DANGER_TOAST', i18n.global.t('company.network_hardware.loading_error'), {root: true})
      return
    }
    // Загружаем в store
    commit('SET_NETWORK_HARDWARE', result.data)
  },

  async addNetHardware(context, payload) {
    // Добавляем сетевое оборудование
    const result = await api.add({ company_id: payload.company, network_hardware_id: null, data: payload})
    if (result.status) {
      context.commit('ADD_NETWORK_HARDWARE', result.data)
      await context.commit('SUCCESS_TOAST', i18n.global.t('general.success.network_hardware_create'), {root: true})
      return
    }
    // Если не удается создать Сетевое оборудование
    if (result.errors.length  && !result.errors[0].response.data.freemium) {
    context.commit('DANGER_TOAST', i18n.global.t('company.network_hardware.creating_error'), {root: true})
    }
    // Превышен лимит анкет для демо компании
    else  context.commit('BANNER', {
          title: i18n.global.t('general.errors.quota.title'), 
          message: i18n.global.t('general.errors.quota.message'), 
          mail: i18n.global.t('general.errors.quota.mail')
        }, {root: true})
  },

  async deleteNetHardware(context, payload) {
    // Удаляем сетевое оборудование
    const result = await api.delete({ company_id: payload.company_id, network_hardware_id: payload.form_id})
    if (result.status === 204) {
      context.commit('DELETE_NETWORK_HARDWARE', payload.form_id)
    }
    if (result.status) {
      await context.commit('SUCCESS_TOAST', i18n.global.t('general.success.network_hardware_delete'), {root: true})
    }
    return !result.errors.length
  },

  updateNetHardware: _.debounce((
    async ({ dispatch, commit }, payload) => {
      // Обновляем поле анкеты
      let data = {}
      data[payload.field_name] = payload.value

      const result = await api.update({
        company_id: payload.form.company,
        network_hardware_id: payload.form.id,
        data,
      })
      if (payload.field_name === 'name') {
        await dispatch('fetchNetHardwares', payload.form.company)
      }
      if (result.errors.length) {
        commit('DANGER_TOAST', i18n.global.t('company.network_hardware.editing_error'), {root: true})
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        dispatch('getNetHardware', { company_id: payload.form.company, network_hardware_id: payload.form.id})
        return false
      } else {
        //Обновляем данные в анкете
        dispatch('getNetHardware', { company_id: payload.form.company, network_hardware_id: payload.form.id})
      }
      return true
    }
  ), 500),
}