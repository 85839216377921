<template>

<transition name="fade">
<div class="bloading" v-if="show">
  <div class="bloading__background"></div>

  <div class="spinner gray"></div>
</div>
</transition>


</template>

<script>

export default {
  name: 'b-loading',
  
  props: {
    show: Boolean
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/style.sass"

.bloading
  display: flex
  position: fixed
  align-items: center
  justify-content: center
  top: 0
  left: 0
  width: 100%
  height: 100vh
  background: rgba(0, 0, 0, 0.15)
  z-index: 500


</style>
