<template>
  <section :class="['input_section']">
    <label :for="`input_id_${unique_id}`" v-click-outside="closeComment">
      <p  class="input_name"> {{ label_name }} <span style="color: red" v-if="required">*</span>
        <span v-if="filling_status">
          <img v-if="value" class="checkmark" src="@/assets/img/green-check.svg" alt="">
          <img v-else class="checkmark no_filled" src="@/assets/img/black-check.svg" alt="">
        </span>
      </p>
      <div :class="['text_input', validation_error ? 'input_error' : '']" >
        <input
          :type="type"
          :value="value"
          :id="`input_id_${unique_id}`"
          @input="addEmail($event)"
          :required="required"
          :placeholder="placeholder"
        />

        <div class="icon" v-if="user.is_analyst && comment">
          <img
            src="@/assets/img/add_comment.svg"
            @click="show_comment = true"
            alt="add_comment"
          />
          <div class="number_of_comments" v-if="comments_sorted.length">
            {{ comments_sorted ? comments_sorted.length : '' }}
          </div>
        </div>
      </div>
      <small class="input_hint" v-if="hint">{{ hint }}</small>

      <div class="comment_board" v-if="show_comment && comment && !user.is_client" >
        <form @submit.prevent="addCommentHandle()">
          <div class="add_comment">
<!--            <div class="user">-->
<!--              <div class="user_avatar">{{ user.username[0].toUpperCase() }}</div>-->
<!--            </div>-->
            <input v-model="new_comment.text" required :placeholder="$t('general.add_comment')" />

            <div class="comment_buttons">
              <div
                :class="['add_button', is_comment_filled ? 'success' : '']"
                @click="addCommentHandle()"
              >{{ $t('general.add') }}</div>
            </div>
          </div>
        </form>

        <!-- already created comments -->
        <div class="created_comments">
          <div class="detail_comment" v-for="(c, index) in comments_sorted" :key="index" >
<!--            <div class="user">-->
<!--              <div class="user_avatar">{{ c.create_by }}</div>-->
<!--            </div>-->

            <div class="text">
              <div class="comment_title">

                <div class="info"
                     v-for="create_by in createByUser(c.create_by)"
                     :key="create_by.id">
                  <span class="name">{{ create_by.username }}</span>
                  <span class="date">{{ c.created.split('T')[0] }}</span>
                </div>

                <div class="action">
                  <button
                    class="delete_button"
                    @click="show_delete_comment = true;
                    current_comment_index = c.id"
                    type="button"
                  >{{ $t('general.delete') }}</button>
                </div>
              </div>

              <p class="comment_text">
                {{ c.text }}
              </p>
            </div>
          </div>
        </div>

      </div>

      <p class="error_text" v-if="validation_error">{{ validation_error }}</p>

      <delete-modal
        @approve="handleDeleteComment"
        @close="show_delete_comment = false"
        :show="show_delete_comment"
        title="Удаление комментария"
        desc="Вы уверены, что хотите удалить данный комментарий?"
      ></delete-modal>
    </label>

  </section>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import { throttle } from 'lodash'
import DeleteModal from '@/components/global/modals/deleteModal.vue'

export default {
  name: 'EmailInputEmit',
  components: {DeleteModal},

  props: {
    'value': {
      type: [String, Number]
    },
    'field_name': {
      type: String
    },
    'tagId': {
      type: String,
      default: ''
    },
    'label_name': String,
    'placeholder': String,
    'required': Boolean,
    'error': String,
    'type': {
      type: String,
      default: 'text'
    },
    'hint': {
      type: String
    },
    'comment': String,
    'query_param': Number,
    'obj': String,
    'filling_status': Boolean,
  },

  emits: ['update'],

  data() {
    return {
      unique_id: '',
      validation_error: '',
      new_comment: {
        text: ''
      },
      show_delete_comment: false,
      current_comment_index: 0,

      show_comment: false,
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  computed: {
    ...mapState(['company']),
    ...mapState('company', ['company', 'comments', 'users']),
    ...mapState('process', ['process']),
    ...mapState('system', ['system']),
    ...mapState('user', ['user']),

    is_comment_filled() {
      return !!this.new_comment.text.length
    },

    comments_sorted() {
      let comment = this.comment
      let comments_sorted = [...this.comments]
      return comments_sorted.filter(c => c.field_name === comment && c.object_id===this.get_object_id)
    },

    get_object_id() {
      return this.obj==='process' ? this.process.id : this.system.id
    }
  },

  methods: {
    ...mapActions('company', ['addComments', 'deleteComment']),

    closeComment() {
      this.show_comment = false
    },

    handleDeleteComment() {
      let cid = this.company.id
      let ci = this.current_comment_index
      this.deleteComment({current_index: ci, company_id: cid})
      this.show_delete_comment = false
      this.show_comment = true
    },

    addCommentHandle() {
      this.show_comment = true
      if(this.new_comment.text?.length) {
        this.addComments({
          content_type: 9,
          create_by: parseInt(this.user.id),
          field_name: this.field_name,
          object_id: this.get_object_id,
          text: this.new_comment.text,
          company_id: this.company.id
        })
      }
      this.new_comment.text = ''
    },

    createByUser(id) {
      return this.users.filter(user => user.id === id)
    },

    addEmail: throttle( function(e){
       if (!e.target.value.length || e.target.value.match(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/g)
          ) {
          this.$emit('update', this.field_name, e.target.value)
          this.validation_error = ''
        } else {
         this.validation_error = this.$t('general.errors.add_email')
       }
      }, 3000)
  }

}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>
