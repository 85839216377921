import deleteModal from "./deleteModal"
import bModal from './bModal'
import bModalList from './bModalList'
import bLoading from './bLoading'

export default [
  deleteModal,
  bModal,
  bModalList,
  bLoading
]