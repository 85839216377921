<template>
<section>
  <b-modal :show="show" @close="closeModal" :title="title">
    <form @submit.prevent='approveModal'>
      <div class="modal_style">

        <p class="warn_message" style="font-size: 16px">{{ desc }}</p>

        <div class="modal_footer">
          <button class="default" type="button" @click="closeModal">
            {{ text_cancel_button }}
          </button>
          <button class="danger_color" type="submit">{{ $t('general.delete') }}</button>
        </div>
      </div>
    </form>
      
  </b-modal>
</section>
</template>

<script>
import BModal from "@/components/global/modals/bModal.vue";

export default {
  name: "delete-modal",
  components: {BModal},

  props: {
    show: Boolean,
    title: String,
    desc: String,
    cancel_text: {
      type: String,
      default: null,
    }
  },

  computed: {
    text_cancel_button() {
      return this.cancel_text || this.$t('general.cancel')
    }
  },

  methods: {
    closeModal(e) {
      e.stopPropagation()
      this.$emit('close')
    },

    approveModal(e) {
      e.stopPropagation()
      this.$emit('approve')
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>