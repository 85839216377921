<template>
  <section>
    <b-modal  
      :show=show_banner  @close='closeModal'
      :title="banner.title"> {{ banner.message }}
      <a v-if="banner.mail.length" :href="`mailto:${banner.mail}`">{{ banner.mail }}</a>
    </b-modal>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'BannerContainer',

  data() {
    return {
      show_banner: false
    }
  },

  computed: {
    ...mapState(['banner'])
  },

  watch: {
    'banner.message': {
      handler() {
        this.show_banner = !!this.banner.message.length
      }
    }
  },


  methods: {
    ...mapMutations(['BANNER']),

    closeModal() {
      this.BANNER( { 'title': '', 'message': '', 'mail': ''})
      this.show_banner = false
    }
  }
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/style.sass'
</style>