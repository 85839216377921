export default {
  percent(state) {
    const requires_process = new Set([
      //#1
      'responsible_person',
      'responsible_person_position',
      'responsible_email',
      //#2
      'purpose',
      'have_teens',
      //#3
      'positions',
      //#5
      'auto_decision',
      //#7
      'systems_processing',
    ])

    const processKeys = Object.keys(state.process)
    let totalsFilled = 0

    processKeys.forEach(key => {
      if (requires_process.has(key)) {
        const value = !!state.process[key]
        const process_key = state.process[key]
        if (value) {
          if (['string','number', 'boolean'].includes(typeof process_key)) {
              totalsFilled++
          }
          if (['object'].includes(typeof process_key)) {
            Object.keys(process_key).length !== 0 ? totalsFilled++ : ''
          }
        }
      }
    })

    let result = requires_process.size
    result = Math.round(totalsFilled / result * 100)

    return Math.max(Math.min(result, 100), 0)
  },

  document_in_any_source_subject(state) {
    const tp_ids = state.process_third_parties.filter(tp => !tp.is_received).map(tp => tp.id)
    return state.source_subjects.some(ss => ss.is_paper)
      || state.process_system_subjects.some(pss => pss.is_paper)
      || state.process_third_party_subjects.some(ps => tp_ids.includes(ps.process_third_party) && ps.is_paper)
  },
}