<template>
  <section :class="['input_section']">
    <label :for="`input_id_${unique_id}`" v-click-outside="closeComment">
      <p class="input_name">{{ label_name }} <span style="color: red" v-if="required">*</span></p>
      <div :class="['text_input', validation_error ? 'input_error' : '']" >
        <input
          class="none_border"
          :type="type"
          :value="value"
          :id="`input_id_${unique_id}`"
          @input="addIpAddress($event)"
          :required="required"
          :placeholder="placeholder"
        />
      </div>
      <small class="input_hint" v-if="hint">{{ hint }}</small>
      <p class="error_text" v-if="validation_error">{{ validation_error }}</p>
    </label>

  </section>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import { throttle } from 'lodash'

export default {
  name: 'IpAddressEmit',

  props: {
    'value': {
      type: [String, Number]
    },
    'field_name': {
      type: String
    },
    'tagId': {
      type: String,
      default: ''
    },
    'label_name': String,
    'placeholder': String,
    'required': Boolean,
    'error': String,
    'type': {
      type: String,
      default: 'id_address'
    },
    'hint': {
      type: String
    },
  },

  emits: ['update'],

  data() {
    return {
      unique_id: '',
      validation_error: '',
      show_delete_comment: false,

      show_comment: false,
    }
  },

  created() {
    this.unique_id = this.tagId ? this.tagId : Math.random().toString().slice(2, 8)
  },

  methods: {
    ...mapMutations(['SUCCESS_TOAST', 'DANGER_TOAST']),
    ...mapActions('company', ['addComments', 'deleteComment']),

    closeComment() {
      this.show_comment = false
    },

    addIpAddress: throttle( function(e){
       if (!e.target.value.length || e.target.value.match(
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/)
          ) {
          this.$emit('update', this.field_name, e.target.value)
          this.validation_error = ''
        } else {
         this.validation_error = this.$t('general.errors.add_ip_address')
       }
      }, 3000)
  }

}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"
</style>
