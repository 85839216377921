export default {
  SET_NETWORK_HARDWARES(state, forms) {
    state.network_hardwares = forms
    state.network_hardwares_loaded = true
  },

  SET_NETWORK_HARDWARE(state, form) {
    state.network_hardware = form
  },

  UPDATE_NETWORK_HARDWARE(state, new_data) {
    state.network_hardwares = {...state.network_hardwares, ...new_data}
  },

  DELETE_NETWORK_HARDWARE(state, id) {
    state.network_hardwares.splice(state.network_hardwares.findIndex(s => s.id === id), 1)
  },

  ADD_NETWORK_HARDWARE(state, network_hardware) {
    state.network_hardwares.push(network_hardware)
  },
}
