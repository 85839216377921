import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales'

import Api from './services/api'


const app = createApp(App)
app.use(i18n)

const api = new Api(store.state.server)
app.config.globalProperties.$api = api


app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (el===event.target) {
        // Если кликнули на элемент на котором записана директива
      }
      
      if (el.contains(event.target)) {
        // если кликнули на элемент, который находится внутри директивы
      }
      if (!(el===event.target || el.contains(event.target))) {
        // console.log('click on background')
        // вызов функции, переданной через имя в директиву
        binding.value()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

// install custom inputs
import inputs from '@/components/global/inputs'
import modals from '@/components/global/modals'
import comps from '@/components/global/default'

let components = [...inputs, ...modals, ...comps]
components.forEach(component => {
  app.component(component.name, component)
})

app.use(store).use(router).mount('#app')

export { api }
