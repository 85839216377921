<template>
<section class="input_section"
         @keypress="letters += $event.key"
         tabindex="0">
    <p class="input_name"> {{ field_name }} 
      <img  v-if="!!hint" style="opacity: .5"
            src="@/assets/img/question.svg"
            :title="hint"/>
    </p>

    <div class="custom_select" v-click-outside="closeSelect">
      <div :class="['parent_block']"
           style="height: auto"
           @click="show_fold_menu = true">
        <div v-if="selected_field.length">{{ getFieldValue() }}</div>
        <div class="input_name" v-else> {{ placeholder }} </div>
        <div class="icon" v-if="show_fold_menu"><img src="@/assets/img/arrow-down-gray.svg" alt="arrow-down-gray"/></div>
        <div class="icon" v-if="!show_fold_menu"><img src="@/assets/img/arrow-up-gray.svg"  alt="arrow-up-gray"/></div>
      </div>

      <div class="fold_block" v-if="show_fold_menu" style="overflow-y: scroll; max-height: 500px">
        <div
            :class="['block', isValueEqualField(option) ? 'selected' : '']"
            v-for="(option, index) in filtered_elements"
            :key="option.id || index"
            :style="computedStyle(option)"
            @click="selectValue(option)">
          <div class="text" >{{ typeof(option) === 'object' ? option.name : option }}</div>
          <div class="icon">
              <img src="@/assets/img/check-icon.svg" v-if="isValueEqualField(option)" alt="check-icon"/>
          </div>
        </div>
      </div>
    </div>


</section>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'MultiSelectInput',

  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    value: String,
    field_name: String,
    available_elements: Array,
    placeholder: String,
    hint: String,
    narrow: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      show_fold_menu: false,
      selected_field: [],
      letters: '',
    }
  },

  created() {
    if (this.value) {
      this.selected_field = this.value.split(';')
    } else {
      this.selected_field = [...this.modelValue]
    }
    this.debouncedResetLetters = _.debounce(this.resetLetters, 5000)

  },

  watch: {
    letters: {
      handler() {
        this.debouncedResetLetters()
      }
    }
  },

  computed: {
    filtered_elements() {
      if (typeof this.value === 'object') {
        return this.available_elements.filter((el) => !this.value.includes(el.id)).map(el => el.name)
      } else {
        return this.available_elements.filter((elem) => {
          return elem.toLowerCase().startsWith(this.letters.toLowerCase())
        })
      }
    }
  },

  methods: {
    closeSelect() {
      this.show_fold_menu = false
      this.letters = ''
    },

    getFieldValue() {
      const selectedNames = this.selected_field
        .map(fieldValue => {
          const foundOption = this.available_elements.find(option => {
            if (typeof(option) === 'object') {
              return option.value === fieldValue
            } else {
              return option === fieldValue
            }
          })

          if (foundOption) {
            return typeof(foundOption) === 'object' ? foundOption.name : foundOption
          }
        })
        .filter(Boolean)

      return selectedNames.join(', ')
    },

    isValueEqualField(value) {
      if (typeof(value) === 'object') {
        return this.selected_field.includes(String(value.value))
      } else {
        return this.selected_field.includes(value)
      }
    },

    selectValue(option) {
      if (typeof(option) === 'object') {
        const index = this.selected_field.findIndex(selected => selected === option.value)
        if (index === -1) {
            this.selected_field.push(option.value)
          } else {
            this.selected_field.splice(index, 1)
          }
      } else {
        const index = this.selected_field.findIndex(selected => selected === option)
        if (index === -1) {
          this.selected_field.push(option)
        } else {
          this.selected_field.splice(index, 1)
        }
      }
      this.$emit('update', this.selected_field.join(';'))
      this.letters = ''
    },

    //Вычисляем отступы между строк
    computedStyle(option) {
      if (option.length > 200 && option.length < 300) {
        return 'height: 100px'
      } else if (this.narrow && option.length > 100) {
        return 'height: 150px'
      } else if (this.narrow && option.length > 250) {
        return 'height: 300px'
      }
      return ''
    },
    
    resetLetters() {
      this.letters = ''
    }
  },

}
</script>

<style scoped lang="sass">
@import "@/assets/sass/style.sass"

.input_section:focus
  outline: none
</style>