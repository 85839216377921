export default {
  companies: [],
  company_loaded: false,
  companies_loaded: false,

  company: {
    id: 0,
    name: null,
    use_security_forms: false,
    use_percents: false
  },
  company_tags: [],

  // Основные анкеты
  information: {},
  offices: [],

  site_app: {},
  sites: [],
  site_forms: [],

  apps: [],
  // Приложение которое сейчас редактируем
  edited_app: null,

  app_forms: [],

  commission: {},

  department: {},

  // Ключи
  keys: [],
  public_key: null,

  subjects: [],

  third_parties: [],
  third_party_subjects: [],

  directory_items: [],
  directory_items_loaded: false,

  directory_category_list: [
    'other_personal_data',
    'special_category_data',
    'biometric_personal_data'
  ],

  macro_goals: [],

  risks: [],

  documents : [],

  comments: [],

  users: [],

  subscriptions: [],

  mailing_dates: [],

  detail_dashboard: [],

  editor_email: null,
  //Количество анкет выводимых за один раз
  limit_records : 100,

  sections_comments:[],

  address_items: [],

  mailing_context: {},

  company_process_systems: [],

  process_subject_other_actions: [],

  company_third_parties_details: [],
  
  threat_form : {},

  criminal: {},

  init_technologies: {},

  security_technologies: {},

  threat_assesments: [],

}
