import i18n from '@/locales'
import {api} from '@/main'
import _ from 'lodash'
import {checkApiResponse, ProcessClient, SystemClient} from '@/api/clients'


export default {
  async fetchSystems(context, payload) {
    const systemClient = new SystemClient(payload.company_id)
    const result = await systemClient.fetchAll(payload.limit, payload.offset)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEMS', result.data)
    }
  },

  async orderingSystems(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/systems/?offset=${payload.offset}&limit=${payload.limit}&ordering=${payload.ordering}`,
      context,
      {},
      'SET_SYSTEMS',
      // i18n.global.t('company.systems.loading_processes_error'),
    )
  },

  async fetchAllSystemData(context, payload) {
    let result = await api.query(
      'GET',
      `/companies/${payload.company}/systems/${payload.system_id}/init_data/`,
      context,
      {},
      'SET_INIT_SYSTEM_DATA'
    )
    await context.dispatch('company/fetchThirdParties', payload.company, {root: true})

    // if (!result) {
    //   await context.dispatch('fetchAllProcessData', payload)
    // }
  },

  async fetchSystem(context, payload) {
    // Получаем систему
    const systemClient = new SystemClient(payload.company)
    const result = await systemClient.fetch(payload.system_id)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM', result.data)
    }
  },

  async addSystem(context, payload) {
    // Добавляем систему
    const result = await api.add({url: `/api/v2/companies/${payload.company}/systems/`, data: payload})
    if (!result.errors.length) {
        context.commit('ADD_SYSTEM', result.data)
        context.commit('SUCCESS_TOAST', i18n.global.t('general.success.system_create'), {root: true})
        return result
    }
    // Если не удается создать систему
    if (result.errors.length  && !result.errors[0].response.data.freemium) {
        context.commit('DANGER_TOAST', i18n.global.t('company.systems.creating_error'), {root: true})
    }
    // Превышен лимит анкет для демо компании
    else  context.commit('BANNER', {
          title: i18n.global.t('general.errors.quota.title'), 
          message: i18n.global.t('general.errors.quota.message'), 
          mail: i18n.global.t('general.errors.quota.mail')
        }, {root: true})
  },

  async deleteSystem(context, payload) {
    // Удаляет систему
    const systemClient = new SystemClient(payload.company)
    const result = await systemClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM', payload.id)
    }
  },

  updateSystem: _.debounce((
    async (context, payload) => {
      // Обновляем поле системы
      let fields = {}
      fields[payload.field_name] = payload.value
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_SYSTEM', {id: payload.form.id, fields, getters: context.getters})
      // Теперь можно актуальные проценты передать вместе с данными на сервер
      const systemClient = new SystemClient(payload.form.company)
      const result = await systemClient.update(
        payload.form.id,
        {...fields, percent: context.getters.percent}
      )
      await checkApiResponse(result, context)
      if (!result) {
        // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
        await context.dispatch('fetchSystem', { company: payload.form.company, system_id: payload.form.id })
      }
    }
  ), 500),

  async updateSystemFields(context, payload)  {
    // Обновляем поля системы
      let fields = {}
      fields = payload.value
      // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_SYSTEM', {id: payload.form.id, fields, getters: context.getters})
      // Теперь можно актуальные проценты передать вместе с данными на сервер
      const systemClient = new SystemClient(payload.form.company)
      const result = await systemClient.update(
        payload.form.id,
        {...fields, percent: context.getters.percent}
      )
      await checkApiResponse(result, context)
    },

  async deleteSystemDocument(context, payload) {
    // Обновляем поле анкеты
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    context.commit('UPDATE_SYSTEM', {id: payload.form.id, fields, getters: context.getters})
    const systemClient = new SystemClient(payload.form.company)
    const result = await systemClient.update(
    payload.form.id,
    {...fields, percent: context.getters.percent}
    )
    await checkApiResponse(result, context)
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от системы
      await context.dispatch('company/deleteDocument', {uuid: payload.value}, {root:true})
    }
  },

  // Другие источники
  async fetchSourceOthers(context, payload) {
    // Получаем другие источники
    await api.query(
      'GET',
      `/companies/${payload.company}/systems/${payload.system_id}/system_source_others/`,
      context,
      payload,
      'SET_SYSTEM_SOURCE_OTHERS',
    )
  },

  async addSourceOther(context, payload) {
    // Добавляем другой источник
    await api.query(
      'CREATE',
      `/companies/${payload.company}/systems/${payload.system}/system_source_others/`,
      context,
      payload,
      'ADD_SYSTEM_SOURCE_OTHER',
    )
    await context.dispatch('fetchSourceOthers', {...payload, system_id: payload.system})
  },

  async deleteSourceOther(context, payload) {
    // Удаляет другой источник
    await api.query(
      'DELETE',
      `/companies/${payload.company}/systems/${payload.system}/system_source_others/${payload.id}/`,
      context,
      payload,
      'DELETE_SYSTEM_SOURCE_OTHER',
    )
    await context.dispatch('fetchSourceOthers', {...payload, system_id: payload.system})
  },


  // Субъекты
  async fetchSystemSubjects(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company}/systems/${payload.system_id}/system_subjects/`,
      context,
      {},
      'SET_SYSTEM_SUBJECTS',
    )
  },

  async addSystemSubject(context, payload) {
    // Добавляем субъект системы
    await api.query(
      'CREATE',
      `/companies/${payload.company}/systems/${payload.system}/system_subjects/`,
      context,
      payload,
      'ADD_SYSTEM_SUBJECT',
    )
  },

  async deleteSystemSubject(context, payload) {
    // Удаляет субъект системы
    await api.query(
      'DELETE',
      `/companies/${payload.company}/systems/${payload.system}/system_subjects/${payload.id}/`,
      context,
      payload,
      'DELETE_SYSTEM_SUBJECT',
    )
    await context.dispatch('fetchSystemThirdPartySubject', payload)
  },

  async updateSystemSubject (context, payload) {
    let data = {}
    data[payload.field_name] = payload.value

    const result = await api.query(
      'UPDATE',
      `/companies/${payload.company}/systems/${payload.form.system}/system_subjects/${payload.form.id}/`,
      context,
      data,
      'UPDATE_SYSTEM_SUBJECT',
      ''
    )

    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('getSystem', { company: payload.form.company, system_id: payload.form.id })
    }
  },

  // Третьи лица
  async addSystemThirdParty(context, payload) {
    const result = await api.query(
      'CREATE',
      `/companies/${payload.company}/systems/${payload.system}/system_third_parties/`,
      context,
      payload,
      'ADD_SYSTEM_THIRD_PARTY',
    )
    await context.dispatch('company/fetchCompanyThirdPartiesDetails', payload.company, {root: true})
    return result
  },

  async deleteSystemThirdParty(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/systems/${payload.system}/system_third_parties/${payload.id}/`,
      context,
      payload,
      'DELETE_SYSTEM_THIRD_PARTY',
    )
  },

  updateSystemThirdParty: _.debounce((
    async (context, payload) => {
      // Обновляем систему
      let data = {}
      data[payload.field_name] = payload.value
      context.commit('UPDATE_SYSTEM_THIRD_PARTY', {...data, id: payload.form.id })

      await api.query(
        'UPDATE',
        `/companies/${payload.company}/systems/${payload.system}/system_third_parties/${payload.form.id}/`,
        context,
        data,
        null,
        ''
      )
    }
  ), 50),

  async deleteDocumentThirdParty(context, payload) {
      let fields = {}
      fields[payload.field_name] = payload.new_uuids
      context.commit('UPDATE_SYSTEM_THIRD_PARTY', {...fields, id: payload.form.id })

      const result = await api.query(
        'UPDATE',
        `/companies/${payload.company}/systems/${payload.system}/system_third_parties/${payload.form.id}/`,
        context,
        fields,
        null,
        ''
      )
      if (!result.error) {
        //Удаляем документ, если смогли отвязать
        await context.dispatch('company/deleteDocument', {uuid: payload.value}, {root:true})
      }
    },

  async fetchSystemThirdPartySubject(context, payload) {
    return await api.query(
      'GET',
      `/companies/${payload.company}/systems/${payload.system}/system_third_party_subjects/`,
      context,
      {},
      'SET_SYSTEM_THIRD_PARTY_SUBJECTS',
    )
  },

  async addSystemThirdPartySubject(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.system.company}/systems/${payload.system.id}/system_third_party_subjects/`,
      context,
      payload,
      'ADD_SYSTEM_THIRD_PARTY_SUBJECT',
    )
  },

  async deleteSystemThirdPartySubject(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.system.company}/systems/${payload.system.id}/system_third_party_subjects/${payload.id}/`,
      context,
      payload,
      'DELETE_SYSTEM_THIRD_PARTY_SUBJECT',
    )
  },

  updateSystemThirdPartySubject: _.debounce((
    async (context, payload) => {
      // Обновляем систему
      let data = {}
      data[payload.field_name] = payload.value
      await api.query(
        'UPDATE',
        `/companies/${payload.system.company}/systems/${payload.system.id}/system_third_party_subjects/${payload.form.id}/`,
        context,
        data,
        'UPDATE_SYSTEM_THIRD_PARTY_SUBJECT',
        ''
      )
    }
  ), 100),

  async searchSystems(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/systems/?search=${payload.search}`,
      context,
      {},
      'FILTER_SYSTEMS',
      i18n.global.t('company.systems.loading_systems_error'),
    )
  },


  async filteredTagsSystems(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/systems/?tags=${payload.tags}&limit=${payload.limit}`,
      context,
      {},
      'SET_SYSTEMS',
      i18n.global.t('company.systems.loading_systems_error'),
    )
  },

  async fetchSystemInteractions(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company}/systems/${payload.system}/system_interactions/`,
      context,
      {},
      'SET_SYSTEM_INTERACTIONS',
      i18n.global.t('company.systems.loading_systems_error'),
    )
  },


  async addSystemInteraction(context, payload) {
    return await api.query(
      'CREATE',
      `/companies/${payload.company}/systems/${payload.system}/system_interactions/`,
      context,
      payload,
      'ADD_SYSTEM_INTERACTION',
    )
  },

  async deleteSystemInteraction(context, payload) {
    await api.query(
      'DELETE',
      `/companies/${payload.company}/systems/${payload.system}/system_interactions/${payload.id}/`,
      context,
      payload,
      'DELETE_SYSTEM_INTERACTION',
    )
  },

  updateSystemInteraction: _.debounce((
    async (context, payload) => {
      // Обновляем данные
      let data = {}
      data[payload.field_name] = payload.value
      context.commit('UPDATE_SYSTEM_INTERACTION', {...data, id: payload.id })
      await api.query(
        'UPDATE',
        `/companies/${payload.company}/systems/${payload.system}/system_interactions/${payload.id}/`,
        context,
        data,
        null,
        ''
      )
        await context.dispatch('fetchSystemInteractions', payload)
    }
  ), 50),
}
