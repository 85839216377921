<template>
<section class="input_section">
  <p class="input_name" v-if="!no_name">{{ field_name }} <span class="red" v-if="required">*</span></p>
  <div class="custom_select" v-click-outside="closeSelect">
    <div
      class="parent_block"
      @click="show_fold_menu = true"
    >
      <div v-if="date"> {{ datetimeToString(date) }} </div>
      <div v-else> {{ $t('general.modal.link.add_date') }} </div>
      <div class="icon" v-if="show_fold_menu"><img src="@/assets/img/arrow-down-gray.svg" alt="arrow-down"/></div>
      <div class="icon" v-if="!show_fold_menu"><img src="@/assets/img/arrow-up-gray.svg" alt="arrow-up"/></div>
    </div>

    <div class="fold_block" v-if="show_fold_menu" style="box-sizing: border-box">
      <div
        :class="['block', option.custom ? 'date_hover' : '']"
        style="height: 40px"
        v-for="(option, index) in options"
        :key="index"
        @click="selectValue(option)"
      >
        <div class="text">{{ typeof(option) === 'object' ? option.value : option }}
          <span class="input_name" v-if="option.name!=='custom_date'">
            {{ datetimeToString(moveDate(option.name)) }}
          </span>
        </div>
        <div v-if="option.name==='custom_date'" class="date_picker" style="position: relative">
          <date-picker
            class="date_picker__menu"
            v-model="pickerDate"
            placeholder="Срок окончания действия"
            locale="ru-Ru"
            format="dd-MM-yyyy"
            @closed="selectDate"
            :min-date="new Date()"
            :enableTimePicker="false"
          ></date-picker>
        </div>
      </div>

    </div>
  </div>
</section>
</template>

<script>
import DatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { datetimeToString } from '@/services/date'

export default {
  name: 'TieredSelectInput',

  components: {
    DatePicker,
  },

  props: {
    field_name: String,
    date: String,
    options: Array,
    no_name: Boolean,
    default_element: Number,
    required: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      show_fold_menu: false,
      custom_date: null,
      pickerDate: null,
    }
  },

  methods: {
    datetimeToString,

    closeSelect() {
      this.show_fold_menu = false
    },

    selectValue(option) {
      if (option.name==='custom_date') {
        return
      }
      this.$emit('update', this.moveDate(option.name))
      this.closeSelect()
    },

    selectDate() {
      this.$emit('update', this.pickerDate)
      this.pickerDate = null
      this.closeSelect()
    },

    moveDate(value) {
      let date = new Date()
      switch (value) {
        case 'day':
          date.setDate(date.getDate() + 1)
          break
        case 'week':
          date.setDate(date.getDate() + 7)
          break
        case 'month':
          date.setMonth(date.getMonth() + 1)
          break
      }
      return date
    }
  }
}
</script>

<style scoped lang="sass">
  @import "@/assets/sass/style.sass"


  .block:hover:last-child
    .arrow_right
      display: none

  .date_picker__menu
    position: relative
    width: 300px
</style>