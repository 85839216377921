<template>
  <div id="file-drag-drop" class="drag_drop" onfocus="this.className='focused'">
    <form ref="fileform">
      <slot></slot>
    </form>
  </div>
</template>


<script>
import {mapActions, mapMutations, mapState} from 'vuex'

export default {
  name: 'DragDropDocument',

  props:['field_name', 'filetype'],

  data () {
    return {
      dragAndDropCapable: false,
      file: [],
    }
  },

  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable()
    if( this.dragAndDropCapable ){
      // Перебираем все связанные с перетаскиванием события и привязываем их к нашей форме fileform
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
        // Прослушать событие, чтобы обнаружить файл
        this.$refs.fileform.addEventListener(evt, function(e) {
          e.preventDefault()
          e.stopPropagation()
        }.bind(this), false)
      }.bind(this))
      this.$refs.fileform.addEventListener('drop', function(e) {
        if (e.dataTransfer.files.length) {
          this.file = e.dataTransfer.files
          this.submitFile()
        }
      // Связываем локальный компонент с функцией, с которой мы обрабатываем событие drop
      }.bind(this))
    }
  },

  computed: {
    ...mapState(['server']),
    ...mapState('process', ['process']),
  },

  methods: {
    ...mapActions('company', ['addDocument']),
    ...mapActions('process', ['updateProcess']),
    ...mapMutations(['DANGER_TOAST']),
    // Определяем, поддерживает ли браузер перетаскивание
    determineDragAndDropCapable(){
      let div = document.createElement('div')
      return ( ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div ) )
              && 'FormData' in window
              && 'FileReader' in window
    },

    async submitFile(){
      let payload = {}
      if (this.file.length && this.file[0].name?.length) {
          payload = {filetype: this.filetype, file: this.file[0]}
        let result = await this.addDocument(payload)
        if (result) {
           this.$emit('update',this.field_name, result.uuid)
        }
      }
      // this.dragAndDropCapable = false
      // this.file = []
      // payload = {}
    },


    }
}
</script>