import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    redirect: '/login',
  },

  {
    path: '/company/:id/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: 'forms/',
        name: 'forms',
        component: () => import('@/views/CompanyView.vue'),
      },
      {
        path: 'dashboard/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/DashboardView.vue'),
        children: [
          {
            path: 'news/:slug/detail/',
            component: () => import('@/views/news/pages/ShowNews.vue')
          },
        ]
      },
      {
        path: 'directory/',
        component: () => import('@/views/directory/DirectoryVIew.vue'),
      },
      {
        path: 'information/',
        name: 'information',
        component: () => import('@/views/forms/info/Index.vue'),
      },
      {
        path: 'siteappunion/',
        name: 'siteappunion',
        component: () => import('@/views/forms/sitesapps/Index.vue'),
        children: [
          {
            path: 'instruction/:slug',
            component: () => import('@/views/forms/Instruction.vue'),
          }
        ]
      },
      {
        path: 'department/',
        name: 'department',
        component: () => import('@/views/forms/Department.vue'),
        children: [
          {
            path: 'instruction/:slug/',
            component: () => import('@/views/forms/Instruction.vue'),
          }
        ]
      },
      {
        path: 'commission/',
        name: 'commission',
        component: () => import('@/views/forms/Commission.vue'),
        children: [
          {
            path: 'instruction/:slug/',
            component: () => import('@/views/forms/Instruction.vue'),
          }
        ]
      },
      {
        path: 'process/:form_id/',
        name: 'process',
        component: () => import('@/views/forms/process/IndexView.vue'),
        children: [
          {
            path: 'instruction/:slug',
            component: () => import('@/views/forms/Instruction.vue'),
          }
        ]
      },
      {
        path: 'system/:form_id/',
        name: 'system',
        component: () => import('@/views/forms/system/IndexView.vue'),
        children: [
          {
            path: 'instruction/:slug',
            component: () => import('@/views/forms/Instruction.vue'),
          }
        ]
      },
      {
        path: 'security/:form_id/',
        name: 'security',
        component: () => import('@/views/forms/security/IndexView.vue'),
      },
      {
        path: 'system_server/:form_id/',
        name: 'system_server',
        component: () => import('@/views/forms/system_server/IndexView.vue'),
      },
      {
        path: 'workstation/:form_id/',
        name: 'workstation',
        component: () => import('@/views/forms/workstation/IndexView.vue'),
      },
      {
        path: 'network_hardware/:form_id/',
        name: 'network_hardware',
        component: () => import('@/views/forms/network_hardware/NetHardwareView.vue'),
      },
      {
        path: 'documents/',
        component: () => import('@/views/DocumentsView.vue'),
      },
      {
        path: 'news/',
        component: () => import('@/views/news'),
        children: [
          {
            path: '',
            component: () => import('@/views/news/pages/UserNewsList.vue'),
          },
          {
            path: 'add',
            component: () => import('@/views/news/pages/AddNews.vue')
          },
          {
            path: ':slug/edit',
            component: () => import('@/views/news/pages/EditNews.vue')
          }
        ]
      },
      {
        path: 'settings',
        component: () => import('@/views/settings/SettingsIndex.vue'),
      },
      {
        path: 'perechen_processov',
        component: () => import('@/views/edit_docs/perechen_processov_12.vue')
      },
      {
        path: 'soglashenie_o_soblyudenii_bezobastnosti',
        component: () => import('@/views/edit_docs/soglashenie_o_soblyudenii_bezobastnosti_15.vue')
      },
      {
        path: 'users/',
        component: () => import('@/views/UsersView.vue'),
        children: [
          {
            path: 'add',
            component: () => import('@/views/users/UserBoardWrapper.vue'),
          }
        ]
      },
    ]
  },

  //  SECRET FORMS
  {
    path: '/link/:company_id/forms/',
    component: () => import('@/layouts/AnonLayout.vue'),
    children: [
      {
        path: 'information/:form_id/:token',
        component: () => import('@/views/forms/info/Index.vue'),
        name: 'Information',
      },

      {
        path: 'siteappunion/:form_id/:token',
        component: () => import('@/views/forms/sitesapps/Index.vue'),
        name: 'SiteApp',
      },

      {
        path: 'commission/:form_id/:token',
        component: () => import('@/views/forms/Commission.vue'),
        name: 'Commission',
      },

      {
        path: 'department/:form_id/:token',
        component: () => import('@/views/forms/Department.vue'),
        name: 'Positions',
      },

      {
        path: 'security_documents/:form_id/:token',
        component: () => import('@/views/forms/SecurityDocuments.vue'),
        name: 'anon security documents',
      },

      {
        path: 'process/:form_id/:token',
        component: () => import('@/views/forms/process/IndexView.vue'),
        name: 'LinkProcess',
      },

      {
        path: 'system/:form_id/:token',
        component: () => import('@/views/forms/system/IndexView.vue'),
        name: 'LinkSystem',
      },

      {
        path: 'security/:form_id/:token',
        component: () => import('@/views/forms/security/IndexView.vue'),
        name: 'LinkSecurityTool',
      },
      {
        path: 'system_server/:form_id/:token',
        component: () => import('@/views/forms/system_server/IndexView.vue'),
        name: 'LinkServerTool',
      },
      {
        path: 'workstation/:form_id/:token',
        component: () => import('@/views/forms/workstation/IndexView.vue'),
        name: 'LinkWorkstationTool',
      },
      {
        path: 'network_hardware/:form_id/:token',
        component: () => import('@/views/forms/network_hardware/NetHardwareView.vue'),
        name: 'LinkNetHardwareTool',
      },
      {
        path: '',
        name: '404',
        redirect: '/404',
      },
    ],
  },

  // Анкеты доступные по ключу
  {
    path: '/key/:token/:company_id/',
    component: () => import('@/layouts/AnonLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/keys/KeysIndex.vue'),
        name: 'KeyIndex',
      },
    ],
  },

  // Форма для завершения регистрации по ссылке приглашения
  {
    path: '/invite/:token',
    component: () => import('@/views/account/InviteAuthView.vue'),
    name: 'LinkInviteTool',
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/account/LoginView.vue'),
  },

  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/account/CallbackView.vue')
  },

  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/account/LogoutView.vue'),
  },

  // Форма восстановления доступа
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import('@/views/account/RecoveryView.vue'),
  },


  // Форма для сброса пароля
  {
    path: '/password_reset/:token',
    component: () => import('@/views/account/ResetView.vue'),
    name: 'LinkResetTool',
  },

  // Freemium для демо использования
  {
    path: '/registration/:token',
    component: () => import('@/views/account/FreemiumView.vue'),
    name: 'LinkFreemiumTool',
  },

  {
    path: '/404',
    name: '404',
    component: () => import('@/views/pages/NotFoundPage.vue'),
  },

  {
    path: '/500',
    name: 'restrict',
    component: () => import('@/views/pages/WrongPage.vue'),
  },

  {
    path: '/expired',
    name: 'linkExpired',
    component: () => import('@/views/pages/LinkExpiredPage.vue'),
  },

  {
    path: '/completed/',
    name: 'listCompleted',
    component: () => import('@/views/pages/ListCompletedPage.vue'),
  },

  {
    path: '/:pathMatch(.*)*', 
    component: () => import('@/views/pages/NotFoundPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
})

export default router
